<template>
	<!-- <lottie-animation path="https://wver.sprintstaticdata.com/v33/static/front/js/Loader-v6.0.json" /> -->
	<div>
		<div v-if="onLine">
			<!-- <div class="bodymovinanim"></div> -->
			<!-- <div v-if="true" class="bodymovinanim"></div> -->
			<transition name="fade">
						<div class="preloader d-flex align-items-center justify-content-center" v-if="getGlobaLoader()">
							<div class="cssload-container">
								<div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
							</div>
							<div class="bodymovinanim" ref="svgContainer" v-if="getGlobaLoader()"></div>
						</div>
					</transition>

			<router-view
				v-if="this.$route.name == 'LandingPage' || this.$route.name == 'AboutUs' || this.$route.name == 'terms-conditions' || this.$route.name == 'responsible-gaming' || this.$route.name == 'faq'"
				@error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
			<div v-else>
				<DesktopHeader @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
				<MobileHeader @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
				<Login @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
				<SignUp @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
				<section class="sports-gamming-sec" v-if="checkforAccountModule()">
					<div class="sports-exch-container">
						<div class="row g-2">
							<!-- <div class="bodymovinanim"></div> -->
							<SideMenu @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

							<div class="col-md-12 col-lg-12 col-xl-10">
								<div
									:class="($route.name == 'horse-bet-details' || $route.name == 'sport-event-detail') ? 'bet-main-container' : 'body-main-container'">

                                <NewsWalletHeader @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></NewsWalletHeader>

									<div class="center-main-content">
										<div class="row g-2">

											<router-view @error-modal="showErrorModalFunc"
												@success-modal="showSuccessModalFunc"></router-view>

										</div>
									</div>

								</div>
							</div>
						</div>

					</div>
				</section>
				<template v-else>
					<section class="sports-gamming-sec Statement-report">
						<div class="sports-exch-container">
							<div class="row g-0">

								<SideMenu />
								<div class="col-md-12 col-lg-12 col-xl-10">
									<div class="body-main-container">

                                    <NewsWalletHeader></NewsWalletHeader>

									</div>
								</div>
							</div>
						</div>
					</section>
					<router-view @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></router-view>
					<Support></Support>
				</template>
			</div>
		</div>
		<!-- <div v-else>
    <NoInternetConnection />
  </div> -->
		<div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
			ref="successmodal" id="successfullyModal">
			<SuccessModal :message="successMsg" />
		</div>

		<div :class="{ show: showErrorModal }" style="z-index: 9999999999999999999999;"
			class="successfully-wrapper successfullytoaster-sec" id="wrongtoaster">
			<ErrorModal :message="errorMsg" />
		</div>
	</div>

	<GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<!-- <div class="bodymovinanim" ref="svgContainer" v-if="getGlobaLoader()"></div> -->
</template>

<script>
// import bodymovin from 'bodymovin'
import lottie from 'lottie-web';
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; 
import Login from "@/modules/authorization/components/Login.vue";
import SignUp from "@/modules/authorization/components/SignUp.vue";
import SideMenu from './shared/components/header/SideMenu.vue';
import DesktopHeader from './shared/components/header/DesktopHeader.vue';
import MobileHeader from './shared/components/header/MobileHeader.vue';
import NewsWalletHeader from './shared/components/header/NewsWalletHeader.vue';
import AdvertSideBar from './shared/components/advertisment/AdvertSideBar.vue';
import Banner from '@/shared/components/banner/Banner.vue'
import NewEvent from './shared/components/new-event/NewEvent.vue';
import SportSelection from './shared/components/sport-selection/SportSelection.vue';
import Support from './shared/components/support/Support.vue';
import ErrorModal from "./shared/components/modal/ErrorModal.vue";
import SuccessModal from "./shared/components/modal/SuccessModal.vue";
import GamesPop1 from "./shared/components/modal/GamesPop1.vue";
import GamesPop2 from "./shared/components/modal/GamesPop2.vue";

import { setHeaders } from '@/shared/services/headers';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";

import axios from 'axios';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { mapGetters } from 'vuex';
import moment from 'moment';
import socketService from "./socket/sport-socket-service";
// import captchaApi from "./modules/authorization/services/captcha-api";
import { useReCaptcha } from 'vue-recaptcha-v3'
import { provide } from 'vue'

export default {
	name: "App",
	components: {
		// LottieAnimation,
		SideMenu,
		DesktopHeader,
		MobileHeader,
		NewsWalletHeader,
		AdvertSideBar,
		Banner,
		NewEvent,
		SportSelection,
		Support,
		ErrorModal,
		SuccessModal,
		Login,
		SignUp,
		GamesPop1,
		GamesPop2,
	},
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			loading: false,
			showSuccessModal: false,
			successMsg: "",
			showErrorModal: false,
			errorMsg: "",
			isLoading: true,
			refreshInit: false,
		}
	},
	setup() {
		const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

		const recaptcha = async (type) => {
			await recaptchaLoaded();
			const token = await executeRecaptcha(type);
			return token;
		};

		provide('recaptcha', recaptcha);

		return {};
	},
	
	computed: {
		...mapGetters({
			captchaData: 'captchaData', siteSettings: 'siteSettings'
		})
	},
	created() {
		this.getSiteSettingsServiceCall();
		this.socketAllEvents()
	},
	mounted() {
		const svgContainer = document.querySelector('.bodymovinanim');
		if (svgContainer) {
			lottie.loadAnimation({
				container: svgContainer,
				path: 'https://wver.sprintstaticdata.com/v33/static/front/js/Loader-v6.0.json',
				loop: true,
				autoplay: true,
			});
		}

		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();

		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);

		if (!this.checkIsLogin()) {
		}
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},

	methods: {
		
		getGlobaLoader() {
			console.log("getter in the app", this.$store.getters.globalLoader)
			return this.$store.getters.globalLoader;
		},
		getSiteSettingsServiceCall() {
			this.loading = true;
			this.$store.dispatch('setGlobalLoader', true);
			api.get(apiName.SITE_SETTINGS + "?domain=" + window.location.host).then(response => {
				this.loading = false;
				if (response && response.status == 200) {
					this.$store.dispatch('setGlobalLoader', false);
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data?.data
						this.$store.dispatch('setSettings', resData);
						this.setManifest(resData);

						const favElement = document.createElement('link');
						favElement.rel = 'icon';
						favElement.href = resData.aws_url + resData.storage_path.domain_image + resData.fav_logo;
						document.head.insertAdjacentElement('beforeend', favElement);

						const title = document.getElementById("title");
						title.text = resData?.domain_name;

						if (this.checkIsLogin())
							this.getWalletBalance();
					}
				}
			}).catch(error => {
				this.loading = false;
				if (error) {
					console.log("Error Login : ", error[0]);
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
				src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
				sizes: "256x256",
				type: "image/png",
				purpose: 'any'
				}]
			}

			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);

			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
						
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend',metaTag);

			},
		getWalletBalance() {
			this.loading = true;
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
				this.loading = false;
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.exposure = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((moment.unix(user.exp).diff(moment(), 'seconds') / 60) < 5 && !this.refreshInit) {
						this.refreshAuthToken(headers);
					}
					setTimeout(function () {
						this.getWalletBalance();
					}.bind(this), 2000);
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				this.loading = false;
				if (error) {
					console.log("Error Login : ", error[0]);
					this.showErrorModalFunc(error[0]);
				}
			});
		},

		refreshAuthToken(headers) {
			this.refreshInit = true;
			api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
			}).catch(error => {
				if (error) {
					console.log("Error Login : ", error[0]);
					this.showErrorModalFunc(error[0]);
				}
				this.refreshInit = false;
			});
		},
		checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},

		showErrorModalFunc(message) {
			console.log(message)
			this.errorMsg = message;
			this.showErrorModal = true;
			setTimeout(() => {
				this.showErrorModal = false;
			}, 2000);
			//window.scrollTo(0, 0);
		},
		showSuccessModalFunc(message) {
			console.log(message)
			this.successMsg = message;
			this.showSuccessModal = true;
			setTimeout(() => {
				this.showSuccessModal = false;
			}, 2000);
			//window.scrollTo(0, 0);
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		checkforAccountModule() {
			const restrictedRoutes = ['sportsbook-result', 'account-statment', 'live-casino-result', 'casino-result', 'activity-log', 'current-bets'];
			return !restrictedRoutes.includes(this.$route.name);
		},
		socketAllEvents() {
			if (this.$store.getters.stateUser) {
				socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type == "myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.showSuccessModalFunc('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.showSuccessModalFunc('New Notification...')
						// }
					}
				});
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					} else if (data.type == 'league_ban') {
						// league ban condition
					}
				});

			}
		}
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
};

</script>
