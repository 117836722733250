<template>
    <div class="join-us-model-pop-up model-pop-up-login-btn">
        <div class="modal" id="join-btn">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">

                    <div class="login-header">
                        <div class="modal-header">
                            <div class="join-us-head">
                                <h4 class="login-form">Register</h4>
                                <p>Simple steps to get your Online ID with <span>casino</span></p>
                            </div>
                            <div class="close-r-btn">
                                <button @click="refreshData()" type="button" class="close" data-bs-dismiss="modal"><i
                                        class="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="login-body-sec">
                        <div class="modal-body">
                            <div class="Continue-with">
                                <div class="button-whatsapp" v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link?.whatsappno1">
                                    <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank" class="btn-whatsapp">
                                        <i class="fa-brands fa-whatsapp"></i> Whatsapp Now
                                    </a>
                                </div>
                            </div>

                            <div class="text-center">
                                <p class="or-text">OR create account with Mobile Number</p>
                            </div>

                            <div class="input-field mt-3">
                                <div class="row g-2">
                                    <div v-if="siteSettings" class="col-3 col-sm-3 col-md-3">
                                        <select v-model="form.code" class="form-select form-control codein valid"
                                            aria-label="Default select example" aria-invalid="false">
                                            <option :value="allowedCountry.phonecode"
                                                v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                        </select>
                                    </div>
                                    <div class="col-9 col-sm-9 col-md-9">
                                        <div class="input-left">
                                            <input type="number" v-model="form.mobileNo" class="form-control" placeholder="phone number*">
                                            <!-- <i class="fa-solid fa-phone"></i> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                    v-if="showErrorMobile && form.mobileNo == ''">
                                    <p class="wrong-message">Please enter mobile number.</p>
                                </div>

                            
                            </div>

                            <div class="mak-gin mo-number-right">
                                <span v-if="timerRunning" style="margin-right: 10px;color:white">{{ formatTime(minutes) + ':' +
                                                            formatTime(seconds) }}</span>
                                <button :disabled="loadingOtp || timerRunning" type="button" @click="sendOtpCall()" id="otp-btn" class="thm-btn thm-boder-btn otp-btn"
                                :class="{ 'btn-loading': loadingOtp }">
                                    {{ resendOtp ? 'Resend OTP' : 'Get OTP' }}
                                </button>
                            </div>

                            <div class="int-container-box input-field mt-3">
                                <input type="number" v-model="form.otp" placeholder="OTP" class="form-control">
                                <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                        v-if="showValidationError &&  getOtp().length < 6">
                                        <p class="wrong-message">Please enter OTP.</p>
                                    </div>
                            </div>

                            <div class="int-container-box input-field mt-3">
                                <input type="text"  v-model="form.userName" placeholder="Username*" class="form-control">

                                <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                    v-if="showValidationError && form.userName == ''">
                                    <p class="wrong-message">Please enter Username.</p>
                                </div>
                            </div>

                             <div class="int-container-box input-field mt-3">
                                <input v-model="form.password" type="password" placeholder="password*" class="form-control">

                                <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                    v-if="showValidationError && form.password == ''">
                                    <p class="wrong-message">Please enter password.</p>
                                </div>
                            </div>

                            <div class="int-container-box input-field mt-3">
                                <input v-model="form.confirmPassword" type="password" placeholder="Confirm Password*" class="form-control">
                                <div class="thm-heading justify-content-start mt-2 password-wrong-con">
                                    <p v-if="showValidationError && form.confirmPassword == ''" class="wrong-message">Please enter Confirm password.</p>
                                    <p v-if="passwordValidation" class="wrong-message">password and confirm passwords are not matched</p>
                                </div>
                            </div>

                            <div class="int-container-box input-field mt-3">
                                <input type="number" v-model="this.form.referCode" placeholder="Refer code (optional)" class="form-control">
                            </div>


                            <div class="register-btn-sec mt-3">
                                <input type="hidden" id="isDownLine" name="isDownLine" value="">
                                <button type="submit" class="btn thm-but" id="submitBtn" @click="signUpCall()">Register</button>
                                <p class="forpass-in">Already have account?<a ref="login_modal_btn" href="#login-btn" data-bs-toggle="modal"> Log
                                        In</a></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import api from '../services/api';
import * as apiName from '../services/urls';
import SuccessModal from '@/shared/components/modal/SuccessModal.vue';
import ErrorModal from '@/shared/components/modal/ErrorModal.vue';
import { toHandlerKey } from 'vue';
import { Modal } from "bootstrap"

export default {
    name: "SignUp",
    inject:['recaptcha'],
    data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings.country_code ? this.siteSettings.country_code : "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText:''
            },
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            captchaData:null,
            isOtpApiCalled:false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
    mounted(){
        if(this.$route.name == 'sign-up' && !this.checkIsLogin()){
            let loginModal = new Modal('#join-btn');
            loginModal.show();
        }
        else if(this.$route.name == 'sign-up' &&this.checkIsLogin()){
            this.$router.push('/')
        }
    },
    methods: {
        checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: ""
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0
        },
        sendOtpCall() {
            if (!this.loadingOtp) {
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else {
                    this.sendOtpServiceCall();
                }
            }
        },
       async sendOtpServiceCall() {
            this.loadingOtp = true;
            let recaptchaToken = await this.recaptcha();

            api.post(apiName.SEND_OTP, {
                mobile_number: this.form.mobileNo,
                cncode: this.form.code,
                recaptcha_token: recaptchaToken
            }).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.form.captchaText='';
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error)
                    this.showErrorModalFunc(error[0])
            });
        },
        signUpCall() {
            if (!this.loadingSignUp) {
                let o = this.getOtp();
                this.passwordValidation = false;
                if (this.form.mobileNo == "" ) {
                    this.showErrorMobile = true;
                }
                else if (o.length != 6 || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '') {
                    this.showValidationError = true;
                    this.showErrorMobile = true;
                }
                else if (this.form.password != this.form.confirmPassword) {
                    this.passwordValidation = true;
                }
                else {
                    this.signUpServiceCall();
                }
            }
        },
         async signUpServiceCall() {
            this.loadingSignUp = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let recaptchaToken = await this.recaptcha();
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "recaptcha_token": recaptchaToken,
                "cncode" : this.form.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$refs.login_modal_btn.click();
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                if (error)
                    this.showErrorModalFunc(error[0]);
            });
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
         showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },

    },
    components: { SuccessModal, ErrorModal }
};
</script>

<style scoped>
.wrong-message{
    color:red
}
</style>