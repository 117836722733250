const StatmentModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const AccountStatment = () => import(/* webpackChunkName: "account-statment" */ './views/AccountStatment.vue');
const SportsBookResult = () => import(/* webpackChunkName: "sportsbook-result" */ './views/SportsBookResult.vue');
const LiveCasinoResult = () => import(/* webpackChunkName: "live-casino-result" */ './views/LiveCasinoResult.vue');
const CasinoResult = () => import(/* webpackChunkName: "casino-result" */ './views/CasinoResult.vue');
const ActivityLog = () => import(/* webpackChunkName: "activity-log" */ './views/ActivityLog.vue');
const CurrentBets = () => import(/* webpackChunkName: "current-bets" */ './views/CurrentBets.vue');

const StatmentRoutes = {
  path: '/',
  component: StatmentModule,
  children: [
    {
      path: 'account-statment',
      name: 'account-statment',
      component: AccountStatment,
    },
    {
      path: 'sportsbook-result',
      name: 'sportsbook-result',
      component: SportsBookResult,
    },
    {
      path: 'live-casino-result',
      name: 'live-casino-result',
      component: LiveCasinoResult,
    },
    {
      path: 'casino-result',
      name: 'casino-result',
      component: CasinoResult,
    },
    {
      path: 'activity-log',
      name: 'activity-log',
      component: ActivityLog,
    },
    {
      path: 'current-bets',
      name: 'current-bets',
      component: CurrentBets,
    },
  ],
}

export default StatmentRoutes;