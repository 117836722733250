<template>
    <div class="sport-tabs">
        <ul class="home-sports-list nav nav-tabs">

            <!-- <li class="nav-item all-menu" role="presentation">
                <router-link :to="{ name: 'Home' }" class="nav-link" :class="{ active: $route.name == 'Home' }"
                    @click="sportsTab('All')">
                    <img src="@/assets/images/menu-inplay.png" alt="">
                    <span>Inplay</span>
                </router-link>
            </li> -->

            <li class="nav-item" role="presentation" v-for="(sport, n_index) in non_custom_sports" :key="n_index">
                <!-- <router-link :to="{ name: 'Home', params: { type: sport.slug, id: sport.id } }" class="nav-link"
                    :class="{ color1: sport.id == 4, color2: sport.id == 2, color3: sport.id == 1 }"
                    @click="sportsTab(sport.slug, sport.id)"> -->
                <router-link :to="{ name: 'Home', params: { type: sport.slug, id: sport.id } }" 
                :class="{ 'color1': n_index === activeIndex && n_index === 0, 'color2': n_index === activeIndex && n_index === 1,'color3': n_index === activeIndex && n_index === 2 }" @click="sportsTab(sport.slug, sport.id,n_index)">
                    <!-- <i class="fa-solid fa-baseball-bat-ball"></i> -->
                    <img v-if="sport.is_custom === 1" :src="clickApiUrl + sport.sport_icon" alt="">
                    <!-- <img  src="@/assets/images/cricket.png" alt=""> -->
                    <i v-else-if="sport.id == 4" class="d-icon icon-4"></i>
                    <i v-else-if="sport.id == 1" class="d-icon icon-1"></i>
                    <i v-else-if="sport.id == 2" class="d-icon icon-2"></i>
                    <!-- <img  src="@/assets/images/football(1).png" alt=""> -->
                    <!-- <img  src="@/assets/images/tennis-ball.png" alt=""> -->
                    <span class="text-capitalize">{{ sport.slug }}</span>
                </router-link>
            </li>
            <li class="nav-item" role="presentation" v-for="(c_sport, c_index) in custom_sports" :key="c_index">
                <a class="nav-link" @click="sportsTab(c_sport.slug, c_sport.id)">
                    <i class="fa-solid fa-baseball-bat-ball"></i>
                    <img v-if="c_sport.is_custom === 1" :src="clickApiUrl + c_sport.sport_icon" alt="">

                    <i v-else class="d-icon icon-68"></i>
                    <span class="text-capitalize">{{ c_sport.slug }}</span>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'matka' }" @click="withoutLogin()" class="nav-link matka-head-11"
                    :class="{ active: $route.name == 'matka' }">
                    <span><img src="@/assets/images/menu-2378961.png" alt="" /></span>
                    <span>Matka</span>
                </router-link>
            </li>
            <!-- <li class="nav-item" role="presentation">
                <router-link :to="{name: 'Soccer'}" class="nav-link sport-head-3" :class="{ active: $route.name == 'Soccer' }"
                @click="footballTab()">
                    <i class="fa-regular fa-futbol"></i>
                    <span>Football</span>
                </router-link>
            </li>

            <li class="nav-item" role="presentation">
                <router-link :to="{name: 'Tennis'}" class="nav-link sport-head-4" :class="{ active: $route.name == 'Tennis' }"
                @click="tennisTab()">
                    <i class="fa-solid fa-baseball"></i>
                    <span>Tennis</span>
                </router-link>
            </li> -->

            <!-- <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'racing-category', params: { type: 'HORSE_RACE' } }" class="nav-link sport-head-5"
                    :class="{ active: $route.params.type == 'HORSE_RACE' }">
                    <i class="fa-solid fa-horse-head"></i>
                    <span>Horse Racing</span>
                </router-link>
            </li>

            <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'racing-category', params: { type: 'GREY_HOUND' } }" class="nav-link sport-head-6"
                    :class="{ active: $route.params.type == 'GREY_HOUND' }">
                    <i class="fa-solid fa-horse"></i>
                    <span>Greyhound Racing</span>
                </router-link>
            </li> -->
            <!-- <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'games', params: { type: 'casino' } }" class="nav-link sport-head-11"
                    :class="{ active: $route.name == 'games' }">
                    <span><i class="d-icon icon-68"></i></span>
                    <span>casino</span>
                </router-link>
            </li> -->

            <!-- <li class="nav-item" role="presentation">
                <router-link :to="{ name: 'virtual-sport' }" class="nav-link virtual-sport-head"
                    :class="{ active: $route.name == 'virtual-sport' }">
                    <span><img src="@/assets/images/virtual.webp" alt="" /></span>
                    <span>Virtual Sports</span>
                </router-link>
            </li>

            <li class="nav-item" role="presentation">
                <a href="fantasy-cricket.html" class="nav-link fantasy-cricket-head">
                    <span><img src="@/assets/images/cricket-player.png" alt="" /></span>
                    <span>Fantasy Cricket</span>
                </a>
               
            </li> -->
        </ul>
    </div>
    <div v-if="$route.name == 'LandingPage'">
        <a data-bs-toggle="modal" data-bs-target="#login-btn" ref="loginPopup"></a>
    </div>
</template>

<script>
export default {
    name: "SportSelection",
    data() {
        return {
            custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).custom : [],
            non_custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).non_custom : [],
            clickApiUrl: process.env.VUE_APP_CLICK_API + 'v1/aws-s3/download/',
            activeIndex: 0

        }
    },
    created() {
        console.log('check sports_List data get from the local storage in created', JSON.parse(localStorage.getItem('sports_List')))
    },
    methods: {
        withoutLogin() {
            if (this.$route.name == 'LandingPage')
                this.$refs.loginPopup.click();
            return
        },
        allTab() {
            this.$emit('All')
        },
        sportsTab(slug, id,index) {
            this.activeIndex = index;
            this.$emit('sportsSelection', { slug, id })
        },
    }
};
</script>

<style scoped>
.width-100 {
    width: 100% !important;
}

.color1 {
    background-color: #20327b !important;
}

.color2 {
    background-color: #f18521 !important;
}

.color3 {
    background-color: #03b37f !important;
}
</style>