
import { createRouter, createWebHistory } from "vue-router";
const LandingPage = () => import("../modules/landingpage/views/LandingPage.vue");
const Home = () => import("../views/Home.vue");
const AboutUs = () => import("../shared/components/footer/AboutUs.vue")
const TermsAndConditions = () => import("../shared/components/footer/TermsAndConditions.vue")
const ResponsibleGaming = () => import("../shared/components/footer/ResponsibleGaming.vue")
const Faq = () => import("../shared/components/footer/Faq.vue")
const Login = () => import("@/modules/authorization/components/Login.vue");
const SignUp = () => import("@/modules/authorization/components/SignUp.vue");
const ForgetPassword = () => import("@/modules/authorization/components/ForgetPassword.vue");
import SportsRoutes from "@/modules/sports";
import WalletRoutes from "@/modules/wallet";
import RaceRoutes from "@/modules/race";
import VirtualSportRoutes from "@/modules/virtual-sport";
import ReferEarnRoutes from "@/modules/refer-earn";
import BonusRoutes from "@/modules/bonus";
import StatmentRoutes from "@/modules/statment";
import GamesRoutes from "@/modules/games";
import MatkaRoutes from "@/modules/matka";


const routes = [
    {
        path: "/",
        name: "LandingPage",
        component: LandingPage
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
    },
    {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs
    },
    {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs
    },
    {
        path: "/terms-conditions",
        name: "terms-conditions",
        component: TermsAndConditions
    },
    {
        path: "/responsible-gaming",
        name: "responsible-gaming",
        component: ResponsibleGaming
    },
    {
        path: "/faq",
        name: "faq",
        component: Faq
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/sign-up",
        name: "sign-up",
        component: SignUp
    },
    {
        path: "/forget-password",
        name: "forget-password",
        component: ForgetPassword
    },
    SportsRoutes,
    WalletRoutes,
    RaceRoutes,
    VirtualSportRoutes,
    ReferEarnRoutes,
    BonusRoutes,
    StatmentRoutes,
    GamesRoutes,
    MatkaRoutes

];


export default createRouter({
    history: createWebHistory(),
    routes
});

