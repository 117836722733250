<template>
    <header class="header-after-login-wapper desktop-view" loading="lazy">
        <div class="container-fluid">
            <div class="sport-new-header">
                <ul>
                    <li>
                        <router-link to="/home" class="active">
                            <img src="@/assets/images/menu-inplay.png" alt="">
                            Live Match</router-link>
                    </li>
                    <li v-for="(sport,n_index) in non_custom_sports" :key="n_index">
                        <router-link :to="'/'+ sport.name">{{sport.name}}</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'racing-category', params: { type: 'HORSE_RACE' } }">Horse Racing</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'racing-category', params: { type: 'GREY_HOUND' } }">Greyhound Racing</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'games', params: { type: 'casino' } }">Casino</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'matka' }" >Matka</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'virtual-sport' }">Virtual Sports</router-link>
                    </li>
                    <li>
                        <a href="#">Fantasy Cricket</a>
                    </li>
                    <!-- <li v-for="(sport,c_index) in custom_sports" :key="c_index">
                        <router-link :to="'/'+ sport.name">{{sport.name}}</router-link>
                    </li> -->
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
import click_api from '@/shared/services/click_api';
import * as apiName from '@/shared/services/urls';
export default {
    name: 'DesktopHeader',
    data(){
        return{
            non_custom_sports:[],
            custom_sports:[],
        }
    },
    created(){
        this.get_sportsList()
    },
    methods:{
         get_sportsList(){
            this.loading = true;
            click_api.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports=[]
                        for(var i=0;i<all_sports.length;i++){
                            if(all_sports[i].is_custom==0){
                                this.non_custom_sports.push(all_sports[i])
                            }else{
                                this.custom_sports.push(all_sports[i])
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list={}
                        sport_list['custom']=this.custom_sports
                        sport_list['non_custom']=this.non_custom_sports
                        localStorage.setItem('sports_List',JSON.stringify(sport_list))
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                    // this.showErrorModalFunc(error.data.message);
                }
            });
        },
    }
}
</script>

<style></style>