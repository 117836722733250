const WalletModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const Wallet = () => import(/* webpackChunkName: "wallet" */ './views/Wallet.vue');
const WalletHistory = () => import(/* webpackChunkName: "wallet-history" */ './views/WalletHistory.vue');
const WalletWithdraw = () => import(/* webpackChunkName: "wallet-withdraw" */ './views/WalletWithdraw.vue');
const PaymentMethod = () => import(/* webpackChunkName: "wallet-withdraw-details" */ './views/PaymentMethod.vue');
const AddWithdrawAccount= () => import(/* webpackChunkName: "add-withdraw-account" */ './views/AddWithdrawAccount.vue');
const WalletDeposit = () => import(/* webpackChunkName: "wallet-deposit" */ './views/WalletDeposit.vue');
const DepositPaymentMethods = () => import(/* webpackChunkName: "wallet-deposit" */ './views/DepositPaymentMethods.vue');
const TransactionDetails = () => import(/* webpackChunkName: "transaction-details" */ './views/TransactionDetails.vue');
const ManageIds = () => import(/* webpackChunkName: "manage-ids" */ './views/ManageIds.vue');

const WalletRoutes = {
  path: '/',
  component: WalletModule,
  children: [
    {
      path: 'wallet',
      name: 'wallet',
      component: Wallet,
      meta: {
        title: 'Wallet',
      },
    },
    {
      path: 'wallet-history',
      name: 'wallet-history',
      component: WalletHistory,
      meta: {
        title: 'Wallet History',
      },
    },
    {
      path: 'wallet-withdraw',
      name: 'wallet-withdraw',
      component: WalletWithdraw,
      meta: {
        title: 'Withdraw Details',
      },
    },
    {
      path: 'payment-method',
      name: 'payment-method',
      component: PaymentMethod,
      meta: {
        title: 'Withdraw Details',
      },
    },
    {
      path: 'add-withdraw-account/:type',
      name: 'add-withdraw-account',
      component: AddWithdrawAccount,
      meta: {
        title: 'Account Details',
      },
    },
    {
      path: 'wallet-deposit',
      name: 'wallet-deposit',
      component: WalletDeposit,
      meta: {
        title: 'Wallet Deposit',
      },
    },
    {
      path: 'deposit-payment-method',
      name: 'deposit-payment-method',
      component: DepositPaymentMethods,
      meta: {
        title: 'Wallet Payment Method',
      },
    },
    {
      path: 'transaction-details/:type',
      name: 'transaction-details',
      component: TransactionDetails,
      meta: {
        title: 'Transaction Details',
      },
    },
    {
      path: 'manage-ids',
      name: 'manage-ids',
      component: ManageIds,
      meta: {
        title: 'Manage IDs',
      },
    },
  ],
}

export default WalletRoutes;