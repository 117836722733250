const RaceModule = () => import(/* webpackChunkName: "race-module" */ './views/Module.vue');
const RacingCategory = () => import(/* webpackChunkName: "racing-category" */ './views/RacingCategory.vue');
const RacingListDetails = () => import(/* webpackChunkName: "racing-list-details" */ './views/RacingListDetails.vue');
const RacingHome = () => import(/* webpackChunkName: "racing-home" */ './views/RacingHome.vue');
const HorseBetDetails = () => import(/* webpackChunkName: "horse-bet-details" */ './views/HorseBetDetails.vue');

const RaceRoutes = {
    path: '/',
    component: RaceModule,
    name: 'RaceModule',
    children: [
        {
            path: 'racing-category/:type',
            name: 'racing-category',
            component: RacingCategory
        },
        {
            path: 'racing-list-details/:type',
            name: 'racing-list-details',
            component: RacingListDetails
        },
        {
            path: 'racing-home/:type',
            name: 'racing-home',
            component: RacingHome
        },
        {
            path: 'horse-bet-details',
            name: 'horse-bet-details',
            component: HorseBetDetails
        },
    ],
}   

export default RaceRoutes;