<template>
    <!-- <div class="select-language-sec">
        <div class="modal fade" id="select-language-btn" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
            tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="language-header">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalToggleLabel">
                            </h5>
                            <button type="button" class="" data-bs-dismiss="modal" aria-label="Close">
                                <img src="@/assets/images/cancel.png" alt="">
                            </button>
                        </div>
                    </div>
                    <div class="select-language-body">
                        <div class="modal-body">
                            <div class="language-icons">
                                <img src="@/assets/images/language-icon.svg" alt="">
                            </div>
                            <div class="lang-head">
                                <h4>Change Language</h4>
                            </div>
                            <div id="app_1"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div> -->
    <div class="modal fade game-point-note-modal" id="language_selection_pop_up" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border: none;">
                <div class="modal-body">
                    <section class="sub-header-wrapper">
                        <div class="gp-content">
                            <!-- <img data-bs-dismiss="modal" class="gp-closeButton" src="@/assets/images/red-close-btn (1).svg"
                                alt=""> -->
                            <img data-bs-dismiss="modal" class="gp-closeButton" src="../../../assets/images/closeButton.svg"
                                alt="">
                            <div id="app_1"></div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'selectLanguageModal'

}
</script>

<style></style>