<template>
    <header class="login-wrapper-mobile-view">
        <div class="mobile-new-header after-login-header">
            <div class="header-m-wrapper-lft">
                <!-- <span v-if="$route.name != 'Home'" @click="openSideBar()"><img src='../../../assets/images/home2.png'
                        alt=""></span>
                <span v-else @click="openSideBar()"><i class="fa-solid fa-bars"></i></span>
                <span v-if="siteSettings()">
                    <img :src="siteSettings().aws_url + siteSettings().storage_path.domain_image + siteSettings().logo"
                        alt="" />
                </span> -->
                <div class="logo-img">
                    <div class="login-wrapper-mobile-view">
                        <div class="header-m-wrapper-lft">
                            <span v-if="$route.name != 'Home'">
                                <router-link :to="{name:'Home'}">
                                    <!-- <img src='../../../assets/images/home2.png' alt=""> -->
                                    <i class="fas fa-home"></i>
                                </router-link>
                            </span>
                            <span v-else @click="openSideBar()"><img src="@/assets/images/menu.png" alt=""></span>
                        </div>
                        <span v-if="siteSettings()">
                            <img :src="siteSettings().aws_url + siteSettings().storage_path.domain_image + siteSettings().logo"
                                alt="" />
                        </span>
                    </div>
                </div>

                <div class="header-m-wrapper-rgt" v-if="checkIsLogin()">
                    <div class="user-details-1">
                        <div class="point-m-view">
                            <div class="points-rgt-m">
                                <button type="" data-bs-toggle="modal" data-bs-target="#market-tab-btn">
                                    <p>pts: {{ userData?.balance }} | {{ userData?.exposure ? userData.exposure : 0 }}</p>
                                </button>
                            </div>
                        </div>
                        <div class="user-icons">
                            <div class="user-icon-lft-m">
                                <i class="fa-regular fa-user"></i>
                            </div>
                            <div class="user-details-rgt-m-view">
                                <!-- <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample1"
                                    aria-controls="offcanvasExample">
                                    {{ userData?.userid }}
                                </button> -->
                                <button class="dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    {{ userData?.userid }}
                                </button>
                                <div class="dropdown-menu dropdown-menu-end animate slideIn mobile-header-dropdownmenu"
                                    aria-labelledby="navbarDropdown">
                                    <div class="offcanvas-header header-menu-sec">
                                        <div class="offcanvas-title" id="offcanvasExampleLabel">
                                            <div class="recent-bank-sec1">
                                                <div class="thm-heading">
                                                    <h3>{{ userData?.userid }}</h3>
                                                    <p>Last Login: {{ userData?.last_login_at }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="switch-theme">
                                            <div class="day-theme" @click="toggleTheme('dark')">
                                                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/icons/sun.svg">
                                            </div>
                                            <div class="night-theme" @click="toggleTheme('light')">
                                                <img src="https://wver.sprintstaticdata.com/v30/static/front/img/icons/moon.svg">
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="offcanvas-body acc-ofcanvass-body">
                                        <ul class="acc-open-menu">
                                            <!-- <li>
                                                <div class="menu-details-heading">
                                                    <h4>Bonus Information</h4>
                                                </div>
                                                <div class="menu-details-heading">
                                                    <h4>
                                                        <span class="information-icon">
                                                            <router-link :to="{ name: 'bonus' }"
                                                                @click="closeButtonClick()"><img
                                                                    src="@/assets/images/info-icon.svg"
                                                                    alt=""></router-link>
                                                        </span>
                                                    </h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="bonus-sec">
                                                    <div class="credits-list">
                                                        <div class="credits-list-con">
                                                            <h5>Earn Bonus</h5>
                                                            <h6>₹ {{ userData?.available_credits }}</h6>
                                                        </div>
                                                        <div class="credits-list-con">
                                                            <a href="#locked-bonus-modal" data-bs-toggle="modal">
                                                                <h5>Locked Bonus</h5>
                                                                <h6>₹ 400</h6>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="credits-chackn-box">
                                                        <div class="menu-details-heading">
                                                            <h4>Welcome Bonus <span class="information-icon"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#informationModal">
                                                                    <img src="@/assets/images/info-icon.svg" alt=""></span>
                                                            </h4>
                                                        </div>
                                                        <div class="menu-heading-con welcome-bonus">
                                                            <strong>₹ 500</strong>
                                                        </div>
                                                    </div>
                                                    <div class="credits-chackn-box">
                                                        <div class="menu-details-heading">
                                                            <h4>Play with Bonus</h4>
                                                        </div>
                                                        <div class="menu-heading-con">
                                                            <div class="form-check form-switch m-0 p-0">
                                                                <input class="form-check-input" type="checkbox"
                                                                    id="settingCheckDefaults">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li><router-link :to="{ name: 'refer-earn' }" >Refer &
                                                    Earn</router-link></li> -->
                                            <li><router-link :to="{ name: 'account-statment' }"
                                                    >Account Statement</router-link></li>
                                            <li><router-link :to="{ name: 'current-bets' }"
                                                    >Current Bets</router-link></li>
                                            <!-- <li><router-link :to="{ name: 'activity-log' }">Activity Log</router-link></li> -->
                                            <li><button @click="wallet()"
                                                    >Wallet</button></li>
                                            <li><a class="" href="#language_selection_pop_up" data-bs-toggle="modal">Language</a>
                                            </li>
                                            <li><router-link :to="{name:'casino-result'} ">Casino Results</router-link></li>
                                            <!-- <li><router-link :to="{ name: 'live-casino-result' }">Live Casino
                                                    Bets</router-link></li> -->
                                            <!-- <li><router-link :to="{ name: 'sportsbook-result' }">SportBook Bets</router-link>
                                            </li> -->
                                            <li><a class="" href="#set-value-btn" data-bs-toggle="modal">Set Button
                                                    Value</a></li>
                                            <li><a class="" href="#change-password" data-bs-toggle="modal"
                                                    >Change Password</a></li>
                                            <li class="login-seperator"><a @click="logoutBtnClick()">Logout</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="login-register-cmn-btn" v-else>
                    <div class="login-btn">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#login-btn">Login</button>
                        <button type="button" data-bs-toggle="modal" data-bs-target="#join-btn"
                            class="register-btn12">Register</button>
                    </div>
                    <!-- <div class="demo-login-btn">
                        <button type="button" @click="demoUserLogin()">Login with demo ID </button>
                    </div> -->
                </div>

            </div>
        </div>
    </header>
    <div class="market-model-pop-up">
        <div class="modal" id="market-tab-btn">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="market-heading">
                        <div class="modal-header">
                            <h4 class="modal-title">My Market</h4>
                            <button type="button" class="market-close-btn" data-bs-dismiss="modal">
                                <i class="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                    </div>

                    <div class="market-main-body">
                        <div class="modal-body">
                            <div class="data-record">
                                <div class="my-market-box" v-for="(item, index) in marketList" :key="index">
                                    <router-link :to="{name:'sport-event-detail',params:{event_id:parseInt(item?.event_id)}}">
                                        <div class="my-market-box-title">
                                            <span>{{ item?.event_name }}</span>
                                            <h5>{{ dateConverter(item?.event_date) }}</h5>
                                        </div>
                                        <div class="my-market-box-desc">
                                            <ul>
                                                <li><label>Normal</label><span>1</span></li>
                                                <li><label>Bookmaker</label><span>1</span></li>
                                            </ul>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- <div class="my-market-box">
                                    <div class="my-market-box-title">
                                        <span>Cricket -> Australia v West Indies</span>
                                        <h5>02/02/2024 09:00:00 (UTC+05:30)</h5>
                                    </div>
                                    <div class="my-market-box-desc">
                                        <ul>
                                            <li><label>Normal</label><span>1</span></li>
                                            <li><label>Bookmaker</label><span>1</span></li>
                                        </ul>
                                    </div>
                                </div> -->
                                <!-- <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Event Type</th>
                                            <th>Event Name</th>
                                            <th>Match Name</th>
                                            <th>Open Bets</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in marketList" :key="index">
                                            <td>{{ item?.event_type }}</td>
                                            <td>{{ item?.event_name }}</td>
                                            <td>{{ item?.event_name }}</td>
                                            <td>{{ item?.bets_count }}</td>
                                        </tr>
                                    </tbody>
                                </table> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="market-model-pop-up">
        <div class="modal" id="ipdetail-tab-btn">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="market-heading">
                        <div class="modal-header">
                            <h4 class="modal-title">IP Detail</h4>
                            <button type="button" class="market-close-btn" data-bs-dismiss="modal">
                                <i class="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                    </div>
                    <div class="market-main-body">
                        <div class="modal-body">
                            <div class="data-record">
                                <div class="report-row">
                                    <ul>
                                        <li><b>IP:</b><span>2402:e280:230d:637:f494:9f67:c2c9:6ece</span></li>
                                        <li><b>City:</b><span>Jaipur</span></li>
                                        <li><b>Country:</b><span>India</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <MobileSideMenu />
</template>
 
<script >
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";
import moment from "moment";
import MobileSideMenu from "./MobileSideMenu.vue";
import api from '../../services/api'
import * as apiName from "../../services/urls"
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";
export default {
    name: "Header",
    components: {
        MobileSideMenu
    },
    data() {
        return {
            userData: null,
            marketList: [],
            themeValue:null,
        }
    },
    created() {

    },
    mounted() {
        this.themeValue=localStorage.getItem('theme')
        this.userData = this.$store?.getters?.stateUser
        if (this.checkIsLogin()) {
            this.getMyMarketsServiceCall()
        }
        this.themeChanger(this.themeValue)
    },
    methods: {
        toggleTheme(select) {
            if(select==='light'){
                localStorage.setItem("theme",'light');
                this.themeChanger(select)
            }
            else{
                localStorage.setItem("theme",'dark');
                this.themeChanger(select)
            }
        },
        themeChanger(choose){
            const htmlElement = document.querySelector('html');
            // console.log("htmlElemnt",htmlElement,choose)
            if (htmlElement) {
                // if (htmlElement.getAttribute('data-theme') === 'light') {
                    if (choose === 'dark') {
                    htmlElement.removeAttribute('data-theme');
                } else {
                    htmlElement.setAttribute('data-theme', 'light');
                }
            }
        },
        // toggleTheme() {
        //     const htmlElement = document.querySelector('html');
        //     console.log("htmlElemnt",htmlElement)
        //     if (htmlElement) {
        //         if (htmlElement.getAttribute('data-theme') === 'light') {
        //             htmlElement.removeAttribute('data-theme');
        //         } else {
        //             htmlElement.setAttribute('data-theme', 'light');
        //         }
        //     }
        // },
        wallet(){
            getWalletGetwayServiceCall()
        },
        dateConverter(datetime) {
            return moment(datetime).format('D MMM HH:mm');
        },
        logoutBtnClick() {
            this.logoutServiceCall();
        },
        logoutServiceCall() {
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                console.log("Result Logout API : ", response);
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        console.log("response", response);
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal', response?.data?.message);
                            // remove all cookie
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                            console.log('1');
                            this.$store.dispatch('resetAllState');
                            console.log('2');
                            this.$router.push('/');
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error Login : ", error);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated
        },
        openSideBar() {
            document.getElementById("myNav").style.width = "80%";
        },
        closeSideBar() {
            document.getElementById("myNav").style.width = "0%";
        },
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        getMyMarketsServiceCall() {
            let headers = {
                Authorization: `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            this.loading = true;
            this.$store.dispatch('setGlobalLoader', true);
            api.get(apiName.GET_MY_MARKETS + '/' + 'sport', { headers }).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                this.loading = false;
                this.marketList = [];
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.marketList = response.data.data
                            console.log("check my market data ", this.marketList)
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0])
                }
            })
        }
    },
};
</script>