<template>
    <div class="col-md-0 col-lg-0 col-xl-2" v-if="checkforAccountModule()">
        <div class="side-logo user-statement" v-if="siteSettings()">
            <router-link :to="{ name: 'Home' }">
                <img :src="siteSettings().aws_url + siteSettings().storage_path.domain_image + siteSettings().logo" alt="">
            </router-link>
        </div>
    </div>
    <div class="col-md-0 col-lg-0 col-xl-2" v-else>
        <div class="sidebar-left">
            <div class="side-logo" v-if="siteSettings()">
                <router-link :to="{ name: 'Home' }">
                    <img :src="siteSettings().aws_url + siteSettings().storage_path.domain_image + siteSettings().logo" alt="" />
                </router-link>
            </div>
            <div class="inpt-search-box">
                <input type="text" placeholder="search" class="form-control" />
                <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="specail-menu-sec">
                <div class="specail-menu-head">
                    <h5><u>Racing Sport</u></h5>
                </div>

                <div class="specail-navbar-nav">

                    <div class="cmn-navbar-nav horse-racing sports-race">
                        <div class="dropdown">
                            <a href="#" data-bs-toggle="dropdown" class="sports10" aria-expanded="false"
                                id="dropdownMenuButton1">
                                <i class="fa-solid fa-horse"></i>
                                <span>Horse Racing</span>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <div class="cmn-racing-head">
                                    <h5>All Horse Racing</h5>
                                </div>
                                <div class="horse-list-box">
                                    <a href="#" class="dropdown-item">
                                        17:00 Test Event (IN)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:00 MYSORE (IN)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:03 Mildura (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:10 Newcastle (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:10 Fairview (ZA)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:10 Aix-Les-Bains (FR)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:15 Melton (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:19 Northam (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:22 Parkes (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:26 Launceston (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:29 Gloucester Park (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:30 MYSORE (IN)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:34 Mildura (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:40 Aix-Les-Bains (FR)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:41 Newcastle (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:45 Fairview (ZA)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:47 Northam (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:48 Melton (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:53 Parkes (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:57 Launceston (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        01:25 Chepstow (GB)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:15 Melton (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:19 Northam (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:22 Parkes (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:26 Launceston (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:29 Gloucester Park (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:30 MYSORE (IN)
                                    </a>
                                </div>
                            </ul>
                        </div>
                    </div>

                    <div class="cmn-navbar-nav greyhound-racing sports-race">
                        <div class="dropdown">
                            <a href="#" data-bs-toggle="dropdown" class="sport65" aria-expanded="false"
                                id="dropdownMenuButton2">
                                <i class="fa-solid fa-horse-head"></i>
                                <span>Greyhound Racing</span>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                <div class="cmn-racing-head">
                                    <h5>All Greyhound Racing</h5>
                                </div>
                                <div class="horse-list-box">
                                    <a href="#" class="dropdown-item">
                                        17:00 Test Event (IN)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:00 MYSORE (IN)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:03 Mildura (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:10 Newcastle (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:10 Fairview (ZA)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:10 Aix-Les-Bains (FR)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:15 Melton (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:19 Northam (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:22 Parkes (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:26 Launceston (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:29 Gloucester Park (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:30 MYSORE (IN)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:34 Mildura (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:40 Aix-Les-Bains (FR)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:41 Newcastle (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:45 Fairview (ZA)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:47 Northam (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:48 Melton (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:53 Parkes (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:57 Launceston (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        01:25 Chepstow (GB)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:15 Melton (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:19 Northam (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:22 Parkes (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:26 Launceston (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:29 Gloucester Park (AU)
                                    </a>
                                    <a href="#" class="dropdown-item">
                                        16:30 MYSORE (IN)
                                    </a>
                                </div>
                            </ul>
                        </div>
                    </div>

                </div>

                <div class="specail-menu-head">
                    <h5><u>All Sport</u></h5>
                </div>

                <div class="mega-menu-nav">
                    <div class="accordion" id="accordionExample">

                        <div class="accordion-item" v-for="(sport,n_index) in non_custom_sports" :key="n_index">
                            <div class="accordion-header" id="headingtwo">
                                <button class="sports-btn cricket1" type="button" data-bs-toggle="collapse" @click="getLeagues(sport)"
                                    :data-bs-target="'#collapsetwo' + n_index" aria-expanded="false" aria-controls="collapsetwo">
                                    <span>
                                        <!-- <img v-if="sport.id==4" src="@/assets/images/cricket.png" alt="">
                                        <img v-if="sport.id==1" src="@/assets/images/football(1).png" alt="">
                                        <img v-if="sport.id==2" src="@/assets/images/tennis-ball.png" alt=""> -->
                                        <i v-if="sport.id==4" class="d-icon icon-4"></i>
                                        <i v-if="sport.id==1" class="d-icon icon-1"></i>
                                        <i v-if="sport.id==2" class="d-icon icon-2"></i>
                                    </span>
                                    <span>{{sport.name}}</span>
                                </button>
                            </div>
                            <div :id="'collapsetwo' + n_index" class="accordion-collapse collapse" aria-labelledby="headingtwo"
                                data-bs-parent="#accordionExample">
                                <div class="mega-menu-drop-list">
                                    <div class="accordion-body">
                                        <div class="more-details-menu" v-for="(league,l_index) in sport.leagueList" :key="l_index">
                                            <div class="dropdown">
                                                <button @click="getMatches(league)" class="dropdown-toggle testing1" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    {{l_index}}
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li v-for="(match, m_index) in matchesList" :key="m_index">
                                                        <a @click="clickOnSportItem(match)" class="dropdown-item testing2 sports51" href="javascript:void(0);">
                                                             {{ match.event_name }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div class="accordion-header" id="heading33">
                                <button class="sports-btn American-Football" type="button">
                                    <span>
                                        <img src="@/assets/images/casino.png" alt="">
                                    </span>
                                    <router-link :to="{ name: 'games', params: { type: 'casino' } }">Casino</router-link>
                                </button>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div class="accordion-header" id="heading32">
                                <button class="sports-btn Darts" type="button">
                                    <span>
                                        <img src="@/assets/images/menu-2378961.png" alt="">
                                    </span>
                                    <router-link :to="{ name: 'matka' }">Matka</router-link>
                                </button>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div class="accordion-header" id="heading34">
                                <button class="sports-btn soccer" type="button">
                                    <span>
                                        <img src="@/assets/images/chip.png" alt="">
                                    </span>
                                    <span>int Casino</span>
                                </button>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div class="accordion-header" id="headingten">
                                <button class="sports-btn e-game" type="button">
                                    <span>
                                        <img src="@/assets/images/e-sports.png" alt="" />
                                    </span>
                                    <router-link :to="{ name: 'virtual-sport' }">Virtual Sports</router-link>
                                </button>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div class="accordion-header" id="heading27">
                                <button class="sports-btn sumo" type="button">
                                    <span>
                                        <img src="@/assets/images/cricket-player.png" alt="" />
                                    </span>
                                    <span>fantasy cricket</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import click_api from '../../services/click_api';
import * as apiName from '../../services/urls';
export default {
    name: 'SideMenu',
    data(){
        return{
            custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).custom : [],
            non_custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).non_custom : [],
            sportsData:[],
            matchesList:[]
        }
    },
    methods: {
        checkforAccountModule() {
            const restrictedRoutes = ['sportsbook-result', 'account-statment', 'live-casino-result', 'casino-result', 'activity-log', 'current-bets', 'faq'];
            return restrictedRoutes.includes(this.$route.name);
        },
        getLeagues(sport){
            if (sport.leagueList) {
                return;
            }
            this.loading = true;
            click_api.get(apiName.MATCHES_BY_SPORT_ID + sport.id).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        console.log("matches by id", response);
                        this.sportsData=response.data.data
                        sport.leagueList = {};

                        this.sportsData?.forEach(item => {
                            const leagueName = item.league_name;
                            if (!sport.leagueList[leagueName]) {
                                sport.leagueList[leagueName]=[]
                            }
                            sport.leagueList[leagueName].push(item)
                        });

                        // console.log('leagues', sport)

                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                    this.showErrorModalFunc(error.data.message);
                }
            });
        },
        getMatches(matches){
            this.matchesList = matches
        },
         clickOnSportItem(match){
            this.$router.push('/sport-event-detail/' + match.event_id )
        },
         siteSettings() {
			return this.$store.getters.siteSettings
		},
    }
}
</script>

<style></style>