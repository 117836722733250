<template>
    <div class="col-md-0 col-lg-0 col-xl-3" v-if="$route.name == 'horse-bet-details'">
        <div class="open-bet-slip-container" v-if="$route.name == 'horse-bet-details'">
            <div class="video-title">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                                TV
                            </div>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="video-play-container">
                                <div class="accordion-body">
                                    <div class="lock-img">
                                        <img src="@/assets/images/lock.jpg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="open-bet-slip-container" v-else>
            <div class="open-bet-sec">
                <div class="bet-slip-header">
                    <div class="modal-header">
                        <h4 class="modal-title">BET SLIP</h4>
                        <button type="button" class="bet-close-btn" data-bs-dismiss="modal">
                            <i class="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </div>
                <div class="bet-slip-body">
                    <div class="bet-slip-box">
                        <div class="bet-nation-name">
                            <p>India A v Pakistan A</p>
                            <p>MATCH_ODDS</p>
                        </div>
                        <div class="bet-team">
                            <div class="bet-team-name">
                                <span>India A</span>
                            </div>
                            <div class="bet-input-box">
                                <div class="increment-decrement-sec">
                                    <div class="value-button red-minus-btn" id="decrease" onclick="decreaseValue()"
                                        value="Decrease Value">-</div>
                                    <div class="select-digit">
                                        <input type="number" class="form-control" id="number" value="0">
                                    </div>
                                    <div class="value-button green-plus-btn" id="increase" onclick="increaseValue()"
                                        value="Increase Value">+</div>
                                </div>
                            </div>
                        </div>
                        <div class="bet-input back-border">
                            <input type="number" placeholder="Amount" class="form-control">
                        </div>
                        <div class="possible-win">
                            <span>Profit:</span>
                            <h3 class="profit-points">158</h3>
                        </div>
                        <div class="bet-buttons-sec">
                            <div class="placed-bet-btn">
                                <button type="button">1k</button>
                            </div>
                            <div class="placed-bet-btn">
                                <button type="button">2K</button>
                            </div>
                            <div class="placed-bet-btn">
                                <button type="button">5K</button>
                            </div>
                            <div class="placed-bet-btn">
                                <button type="button">10K</button>
                            </div>
                            <div class="placed-bet-btn">
                                <button type="button">20k</button>
                            </div>
                            <div class="placed-bet-btn">
                                <button type="button">25K</button>
                            </div>
                            <div class="placed-bet-btn">
                                <button type="button">50K</button>
                            </div>
                            <div class="placed-bet-btn">
                                <button type="button">100K</button>
                            </div>
                        </div>
                        <div class="place-bet-sec">
                            <button class="placed-bet-btn">
                                <span>Place bet</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div class="col-md-0 col-lg-0 col-xl-3" v-else-if="$route.name != 'account-statment' && $route.name != 'sport-event-detail'">
        <div class="hooper-list">
            <ul>
                <li>
                    <a href="">
                        <img src="@/assets/images/hooper-list-1.png" alt="" />
                    </a>
                </li>
                <li>
                    <a href="">
                        <img src="@/assets/images/hooper-list-3.png" alt="" />
                    </a>
                </li>
                <li>
                    <a href="">
                        <img src="@/assets/images/hooper-list-4.png" alt="" />
                    </a>
                </li>
                <li>
                    <a href="">
                        <img src="@/assets/images/hooper-list-2.png" alt="" />
                    </a>
                </li>
                <li>
                    <a href="">
                        <img src="@/assets/images/hooper-list-3.png" alt="" />
                    </a>
                </li>
                <li>
                    <a href="">
                        <img src="@/assets/images/hooper-list-4.png" alt="" />
                    </a>
                </li>
                <li>
                    <a href="">
                        <img src="@/assets/images/hooper-list-5.png" alt="" />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdvertSideBar'
}
</script>

<style></style>