<template>
    <section class="promo-banner-section" loading="lazy">
        <button class="banner-toggle" @click="toggleDisplay">
            <i class="fas fa-arrow-down"></i> Current Activities</button>
        <div v-if="isVisible" id="myDIV" class="banner-slider-img">
            <Carousel :wrapAround="true" :items-to-show="1" class="owl-carousel owl-theme">  
                <Slide  v-for="(poster,index) in sitePosters" :key="index">
                    <div class="item">
                        <div class="carousel-inner-img">
                            <!-- <img src="@/assets/images/banner-img-1.jpg" alt=""> -->
                            <img :src="siteSettings.aws_url + siteSettings.storage_path.poster_path + poster.image" alt="">
                        </div>
                    </div>
                </Slide>
                <!-- <Slide key="2">
                    <div class="item">
                        <div class="carousel-inner-img">
                            <img src="@/assets/images/banner-img-2.png" alt="">
                        </div>
                    </div>
                </Slide>
                <Slide key="3">
                    <div class="item">
                        <div class="carousel-inner-img">
                            <img src="@/assets/images/banner-img-3.jpg" alt="">
                        </div>
                    </div>
                </Slide>
                <Slide key="4">
                    <div class="item">
                        <div class="carousel-inner-img">
                            <img src="@/assets/images/banner-img-4.jpg" alt="">
                        </div>
                    </div>
                </Slide>
                <Slide key="5">
                    <div class="item">
                        <div class="carousel-inner-img">
                            <img src="@/assets/images/banner-img-5.jpg" alt="">
                        </div>
                    </div>
                </Slide>
                <Slide key="6">
                    <div class="item">
                        <div class="carousel-inner-img">
                            <img src="@/assets/images/banner-img-6.jpg" alt="">
                        </div>
                    </div>
                </Slide>
                <Slide key="7">
                    <div class="item">
                        <div class="carousel-inner-img">
                            <img src="@/assets/images/banner-img-8.jpg" alt="">
                        </div>
                    </div>
                </Slide> -->

                <template #addons v-if="$route.name == 'LandingPage'">
                    <Navigation />
                </template>
            </Carousel>
        </div>
    </section>
    <!-- <DepositModal /> -->
    <div class="top-scroll">
        <div class="container">
            <button v-if="showButton" @click="scrollToTop"><i class="fa-solid fa-angle-up"></i></button>
        </div>
    </div>
</template>

<script>

import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
// import DepositModal from '../modal/DepositModal.vue';

export default {
    name: "Banner",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        // DepositModal,
    },
  data() {
    return {
      isVisible: false,
      showButton: false,
    };
  },
  computed:{
    sitePosters() {
            if(this.siteSettings && this.siteSettings.poster && this.siteSettings.poster.length) {
                return this.siteSettings.poster
                // return this.siteSettings.poster.filter(s => s.poster_type == 1);
            } 
            else {
                return [];
            }
        }
  },
  mounted() {
    // Add a scroll event listener
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // Remove the scroll event listener to prevent memory leaks
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    toggleDisplay() {
      this.isVisible = !this.isVisible;
    },
    handleScroll() {
      // Check the scroll position
      if (window.scrollY >= 50) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

};


</script>