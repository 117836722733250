export const WALLET_HISTORY='api/wallet-history';
export const WALLET_LIMITS='api/wallet-limits';
export const PARENT_PAYMENT_METHODS='api/parent-payment-methods';
export const WALLET_DEPOSIT='api/wallet-deposit';
export const PAYMENT_METHODS='api/payment-methods';
export const ADD_ACCOUNT_LOTUS='api/lotus/create-bank';
export const ADD_ACCOUNT='api/add-account';
export const WALLET_WITHDRAW='api/wallet-withdraw';
export const BANK_DETAILS_LOTUS='api/lotus/get-bank-acount';
export const BANK_DETAILS='api/bank-details';
export const ACCOUNT_DETAILS='api/account-details';
export const DELETE_ACCOUNT='api/delete-account';
export const GET_AUTO_UTR='api/get-auto-utr';
export const CHANGE_STAKE_VALUES='api/change-stake-values';
export const GET_WALLET_GETWAY='api/get-payment-getway-iframe-url';
