const SportsModule = () => import(/* webpackChunkName: "sports-module" */ './views/Module.vue'); 
// const Sports = () => import(/* webpackChunkName: "sports" */ './views/Sports.vue');
// const Inplay = () => import(/* webpackChunkName: "sport-event-detail" */ './views/Inplay.vue');
const SportsEventDetail = () => import(/* webpackChunkName: "sport-event-detail" */ './views/SportEventDetail.vue');
const SportBook1 = () => import(/* webpackChunkName: "games" */ './views/SportBook1.vue');
const SportsRoutes =   {
    path: '/',
    component: SportsModule,
    name: 'SportsModule',
    children: [
      // {
      //   path: 'home',
      //   name: 'Inplay',
      //   component: Inplay
      // },
      {
        path: 'sport-event-detail/:event_id',
        name: 'sport-event-detail',
        component: SportsEventDetail
      },
      {
        path: 'SportBook1',
        name: 'SportBook1',
        component: SportBook1
    },
      // {
      //   path: 'sports',
      //   name: 'Sports',
      //   component: Sports
      // },

    ],
  }

export default SportsRoutes;