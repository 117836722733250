<template>
    <!-- <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample1" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header header-menu-sec">
            <div class="offcanvas-title" id="offcanvasExampleLabel">
                <div class="recent-bank-sec1">
                    <div class="thm-heading">
                        <h3>{{userData?.userid }}</h3>
                        <p>Last Login: {{userData?.last_login_at}}</p>
                    </div>
                </div>
            </div>
            <button type="button" ref="close_btn" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body acc-ofcanvass-body">
            <ul class="acc-open-menu">
                <li>
                    <div class="menu-details-heading">
                        <h4>Bonus Information</h4>
                    </div>
                    <div class="menu-details-heading">
                        <h4>
                            <span class="information-icon">
                                <router-link :to="{name: 'bonus'}" @click="closeButtonClick()"><img src="@/assets/images/info-icon.svg" alt=""></router-link>
                            </span>
                        </h4>
                    </div>
                </li>
                <li>
                    <div class="bonus-sec">
                        <div class="credits-list">
                            <div class="credits-list-con">
                                <h5>Earn Bonus</h5>
                                <h6>₹ {{userData?.available_credits}}</h6>
                            </div>
                            <div class="credits-list-con">
                                <a href="#locked-bonus-modal" data-bs-toggle="modal">
                                    <h5>Locked Bonus</h5>
                                    <h6>₹ 400</h6>
                                </a>
                            </div>
                        </div>
                        <div class="credits-chackn-box">
                            <div class="menu-details-heading">
                                <h4>Welcome Bonus <span class="information-icon" data-bs-toggle="modal"
                                        data-bs-target="#informationModal">
                                        <img src="@/assets/images/info-icon.svg" alt=""></span>
                                </h4>
                            </div>
                            <div class="menu-heading-con welcome-bonus">
                                <strong>₹ 500</strong>
                            </div>
                        </div>
                        <div class="credits-chackn-box">
                            <div class="menu-details-heading">
                                <h4>Play with Bonus</h4>
                            </div>
                            <div class="menu-heading-con">
                                <div class="form-check form-switch m-0 p-0">
                                    <input class="form-check-input" type="checkbox" id="settingCheckDefaults">
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li><router-link :to="{name: 'refer-earn'}" @click="closeButtonClick()">Refer & Earn</router-link></li>
                <li><router-link :to="{name: 'account-statment'}" @click="closeButtonClick()">Account Statement</router-link></li>
                <li><router-link :to="{name: 'current-bets'}" @click="closeButtonClick()">Current Bets</router-link></li>
                <li><a class="" href="activity.html">Activity Log</a></li>
                <li><router-link :to="{name:'activity-log'}">Activity Log</router-link></li>
                <li><router-link :to="{name: 'wallet'}" @click="closeButtonClick()">Wallet</router-link></li>
                <li><a class="" href="#select-language-btn" data-bs-toggle="modal">Language</a></li>
                <li><a class="" href="casinoresult.html">Casino Results</a></li>
                <li><router-link :to="{name:'live-casino-result'}">Live Casino Bets</router-link></li>
                <li><router-link :to="{name:'sportsbook-result'}">SportBook Bets</router-link></li>
                <li><a class="" href="#set-value-btn" data-bs-toggle="modal">Set Button Value</a></li>
                <li><a class="" href="#change-password" data-bs-toggle="modal" @click="closeButtonClick()">Change Password</a></li>
                <li class="login-seperator"><a @click="logoutBtnClick()">Logout</a></li>
            </ul>
        </div>
    </div> -->

    <section class="set-value-button">
        <div class="modal" id="set-value-btn">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="set-value-header">
                        <div class="modal-header">
                            <h4 class="modal-title">Set Button Value</h4>
                            <button @click="resetChips()" type="button" ref="close_button" class="value-btn-close" data-bs-dismiss="modal"><i
                                    class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>

                    <div class="set-value-body">
                        <div class="modal-body">
                            <div class="set-button-value-tabs">
                                <ul class="nav nav-pills mb-1" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="pills-game-btn-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-game-btn" type="button" role="tab"
                                            aria-controls="pills-game-btn" aria-selected="true">
                                            Game Buttons
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-casino-btn-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-casino-btn" type="button" role="tab"
                                            aria-controls="pills-casino-btn" aria-selected="false">
                                            Casino Buttons
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-game-btn" role="tabpanel"
                                        aria-labelledby="pills-game-btn-tab">
                                        <div class="price-level-box">
                                            <table class="table button-value" v-if="chips">
                                                <thead>
                                                    <tr>
                                                        <th>Price Label</th>
                                                        <th>Price Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="text" v-model="chips.chip_name_1" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="number" @change="chips.chip_name_1 = getKFormatter(chips.chip_val_1)"
                                                                v-model="chips.chip_val_1" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" v-model="chips.chip_name_2" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="number" @change="chips.chip_name_2 = getKFormatter(chips.chip_name_2)"
                                                                v-model="chips.chip_val_2" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" v-model="chips.chip_name_3" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="number" @change="chips.chip_name_3 = getKFormatter(chips.chip_name_3)"
                                                                v-model="chips.chip_val_3" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" v-model="chips.chip_name_4" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="number" @change="chips.chip_name_4 = getKFormatter(chips.chip_name_4)"
                                                                v-model="chips.chip_val_4" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" v-model="chips.chip_name_5" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="number" @change="chips.chip_name_5 = getKFormatter(chips.chip_name_5)"
                                                                v-model="chips.chip_val_5" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" v-model="chips.chip_name_6" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="number" @change="chips.chip_name_6 = getKFormatter(chips.chip_name_6)"
                                                                v-model="chips.chip_val_6" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" v-model="chips.chip_name_7" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="number" @change="chips.chip_name_7 = getKFormatter(chips.chip_name_7)"
                                                                v-model="chips.chip_val_7" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" v-model="chips.chip_name_8" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="number" @change="chips.chip_name_8 = getKFormatter(chips.chip_name_8)"
                                                                v-model="chips.chip_val_8" class="form-control" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-casino-btn" role="tabpanel"
                                        aria-labelledby="pills-casino-btn-tab">
                                        <div class="price-level-box">
                                            <table class="table button-value">
                                                <thead>
                                                    <tr>
                                                        <th>Price Label</th>
                                                        <th>Price Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="text" value="25" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" value="25" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" value="50" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" value="50" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" value="100" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" value="100" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" value="200" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" value="200" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" value="500" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" value="500" class="form-control" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type="text" value="1000" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" value="1000" class="form-control" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="set-value-footer">
                        <div class="modal-footer">
                            <button type="button" class="submit-btn123" @click="callChangeStakeValue()">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <LockedBous/>
    <ChangePassword  @error-modal="showErrorModalFunc"
		@success-modal="showSuccessModalFunc"></ChangePassword>
    <selectLanguageModal></selectLanguageModal>
    <BonusModal></BonusModal>
</template>

<script>
import ChangePassword from '@/modules/authorization/components/ChangePassword.vue';
import selectLanguageModal from '../modal/selectLanguageModal.vue';
import BonusModal from '../modal/BonusModal.vue';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { kFormatter } from '@/shared/utils/formatter';
import LockedBous from './LockedBonus.vue';

export default {
    name: 'AccountSideBar',
    components: {
        ChangePassword,
        selectLanguageModal,
        BonusModal,
        LockedBous
    },
    data(){
         return{
            userData:null,
            chips: null,
            chipsDummy: null,
        }
    },
    mounted(){
        this.userData=this.$store?.getters?.stateUser;
        this.chips = {...this.$store?.getters?.chips};
        this.chipsDummy = {...this.$store?.getters?.chips};
    },
    methods: {
        logoutBtnClick() {
            this.logoutServiceCall();
        },

        closeButtonClick(){
            this.$refs.close_btn.click();
        },

        logoutServiceCall() {
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                console.log("Result Logout API : ", response);
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        console.log("response", response);
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                        this.$emit('success-modal', response?.data?.message);
                        // remove all cookie
                        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                        console.log('1');
                        this.$store.dispatch('resetAllState');
                        console.log('2');
                        this.$router.push('/');
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error Login : ", error);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        callChangeStakeValue() {
                let chipsData = {
                    "chips": {
                        ...this.chips
                    }
                }
                let headers = {
                    'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                }
                this.loading = true;
                api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
                    this.loading = false;
                    if (response && response.status == 200) {
                        this.$store.dispatch('setChips', this.chips);
                        this.showSuccessModalFunc('Saved successfully.')
                        this.$emit('save-btn');
                        this.$refs.close_button.click();
                    } else {

                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        this.showErrorModalFunc(error[0]);
                    }
                });
            },
            getKFormatter(num) {
                return kFormatter(num);
            },
            resetChips(){
                this.chips = {...this.$store?.getters?.chips};
            },
            showErrorModalFunc(message) {
				this.$emit('error-modal', message);
			},
			showSuccessModalFunc(message) {
				this.$emit('success-modal', message);
			},
    }

}
</script>

<style></style>