
export const USER_PROFILE='api/user-profile'
export const LOGOUT_API= '/api/logout'
export const AUTH_API= '/api/auth-status'
export const  GET_MY_MARKETS='api/get-my-markets';
// export const SITE_SETTINGS='api/site-settings?domain=+'+ window.location.host;
export const SITE_SETTINGS='api/site-settings';
export const AUTH_STATUS='api/auth-status';
export const GET_FAVOURITE_LIST='api/favorite-list';
export const WALLET_BALANCE='wallet';
export const AUTH_REFRESH='api/auth-refresh';
export const GET_SPORTS= 'v1/sports/management/getSport';
export const GET_TOTAL_MATCHES='/v1/events/count'
export const MATCHES_BY_SPORT_ID = 'v1/events/matches/';
export const CHANGE_STAKE_VALUES='api/change-stake-values'
export const CAPTCHA_API='api/v1/captcha'
export const UPCOMING_MATCHES_LIST = 'v1/events/sports/all';
export const INPLAY_MATCHES_LIST = 'v1/events/matches/inplay';
