const state = {
    user: null,
    chips:null,
    captchaData: null,
    eventDetailHideSections: []
};
const getters = {
    isAuthenticated: state => !!state?.user,    
    stateUser: state => state?.user,
    chips: state => state?.chips,
    token: state => state?.token,
    captchaData: state => state?.captchaData,
    eventDetailHideSections: state => state?.eventDetailHideSections,
};
const actions = {
    setUser ({ commit},user) {
        commit('setUser',user);
    },
    setChips({ commit }, chips) {
        commit('setChips', chips);
    },

    setToken({ commit }, token) {
        commit('setToken', token);
    },
    setCaptchaData({ commit }, token) {
        commit('setCaptchaData', token);
    },
    seteventDetailHideSections({ commit }, filter) {
        commit('seteventDetailHideSections', filter);
    },
};
const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setChips(state, chips) {
        state.chips = chips
    },

    setToken(state, token) {
        state.token = token;
    },
    setCaptchaData(state, token) {
        state.captchaData = token;
    },
    seteventDetailHideSections(state, eventDetailHideSections) {
        state.eventDetailHideSections = eventDetailHideSections;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};