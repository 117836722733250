const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const Games = () => import(/* webpackChunkName: "games" */ './views/Games.vue');
const Lottery = () => import(/* webpackChunkName: "games" */ './views/Lottery.vue');
// const SportBook1 = () => import(/* webpackChunkName: "games" */ './views/SportBook1.vue');
const LiveCasino = () => import(/* webpackChunkName: "games" */ './views/LiveCasino.vue');
const Slot = () => import(/* webpackChunkName: "games" */ './views/Slot.vue');
const FantasyGames = () => import(/* webpackChunkName: "games" */ './views/FantasyGames.vue');
// const Exchange = () => import(/* webpackChunkName: "games" */ './views/Exchange.vue');

const GamesRoutes = {
    path: '/',
    component: GamesModule,
    children: [
        // {
        //     path: 'games/:type',
        //     name: 'games',
        //     component: Games
        // },
        {
            path: 'Lottery',
            name: 'Lottery',
            component: Lottery
        },
        // {
        //     path: 'SportBook1',
        //     name: 'SportBook1',
        //     component: SportBook1
        // },
        {
            // path: 'LiveCasino',
            path: 'games/:type',
            // name: 'LiveCasino',
            name: 'games',
            component: LiveCasino
        },
        {
            path: 'FantasyGames',
            name: 'FantasyGames',
            component: FantasyGames
        },
        {
            path: 'game/:type',
            // path: 'slot',
            name: 'Slot',
            component: Slot
        },
        // {
        //     path: 'Exchange',
        //     name: 'Exchange',
        //     component: Exchange
        // }
    ],
}

export default GamesRoutes;