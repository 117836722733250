<template>
    <section class="set-value-button change-password-model-pop-up">
        <div class="modal" id="change-password">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="set-value-header">
                        <div class="modal-header">
                            <h4 class="modal-title">Change Password</h4>
                            <button type="button" ref="close_btn" class="value-btn-close" data-bs-dismiss="modal"><i
                                    class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>
                    <div class="set-value-body">
                        <div class="change-password-main">
                            <div class="modal-body">
                                <div class="login-form-container">
                                    <div class="login-form-inpt-grp">
                                        <label for="">Old Password</label>
                                        <input type="password" v-model="oldPassword" class="form-control" />
                                        <span v-if="showValidationError && oldPassword == ''" class="error-message">The Old Password field is required</span>
                                    </div>
                                    <div class="login-form-inpt-grp">
                                        <label for="">New Password</label>
                                        <input type="password" v-model="newPassword" class="form-control" />
                                        <span v-if="showValidationError && newPassword == ''" class="error-message">The New Password field is required</span>
                                    </div>
                                    <div class="login-form-inpt-grp">
                                        <label for="">Confirm Password</label>
                                        <input type="password" v-model="confirmPassword" class="form-control" />
                                        <template v-if="showValidationError || passwordValidation">
                                            <span v-if="confirmPassword == ''" class="error-message">The Confirm Password field is required</span> 
                                            <span v-else-if="newPassword!=confirmPassword" class="error-message">Password and confirm password not matched.</span> 
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="set-value-footer change-pswrd-ftr">
                        <div class="modal-footer">
                            <button type="button" @click="submitChangePassword()" class="submit-btn123">Change Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";

export default {
    name: 'ChangePassword',
    inject:['recaptcha'],
    data() {
        return {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            showValidationError: false,
            passwordValidation: false,
        }
    },
    methods: {
        submitChangePassword() {
            if (this.oldPassword == '' || this.newPassword == '' || this.confirmPassword == '') {
                this.showValidationError = true;
            }
            else if (this.newPassword != this.confirmPassword) {
                this.passwordValidation = true;
            }
            else {
                this.callChangePasswordServiceFunction();
            }
        },
        
        async  callChangePasswordServiceFunction() {
            this.loading = true;
            let headers = {
                'Authorization':`Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            let data = {
                "current_password": this.oldPassword,
                "new_password": this.newPassword,
                "confirm_password": this.confirmPassword,
                "recaptcha_token" : await this.recaptcha()
            };
            api.post(apiName.CHANGE_MY_PASSWORD, data,{headers}).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal',response.data.debug[0]);
                        }
                        else {
                            this.$emit('success-modal',response.data.message);
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal',error[0]);
                }
            });
        },
        closeModal() {
            
            this.$refs.click();
        }
    }
}
</script>