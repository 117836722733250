<template>
    <footer class="support-and-help-sec">
        <div class="support-and-socail-icons">
            <div class="support">
                <h3>24X7 Support</h3>
            </div>
            <div class="socail-icons" v-if="siteSettings?.social_link">
                <ul>
                    <li v-if="siteSettings?.social_link?.whatsappno1">
                        <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank"><img
                                src="@/assets/images/whatsapp.png" alt="" /></a>
                    </li>
                    <li v-if="siteSettings?.social_link?.facebook_link">
                        <a :href="siteSettings.social_link.facebook_link" target="_blank"><img
                                src="@/assets/images/facebook.png" alt="" /></a>
                    </li>
                    <li v-if="siteSettings?.social_link?.instagram_link">
                        <a :href="siteSettings.social_link.instagram_link" target="_blank"><img
                                src="@/assets/images/instagram.png" alt="" /></a>
                    </li>
                    <li v-if="siteSettings?.social_link?.telegram_link">
                        <a :href="siteSettings.social_link.telegram_link" target="_blank"><img
                                src="@/assets/images/telegram.png" alt="" /></a>
                    </li>
                    <li v-if="siteSettings?.social_link?.twitter_link">
                        <a :href="siteSettings.social_link.twitter_link" target="_blank"><img
                                src="@/assets/images/twitter.png" alt="" /></a>
                    </li>
                    <li v-if="siteSettings?.social_link.youtube_link">
                        <a :href="siteSettings?.social_link.youtube_link" target="_blank"><img
                                src="@/assets/images/youtube.png" alt="" /></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer-menu-list">
            <ul>
                <li>
                    <router-link :to="{ name: 'AboutUs' }">About Us</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'faq' }">FAQ</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'AboutUs' }">Rules</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'terms-conditions' }">Terms and Conditions</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'responsible-gaming' }">Responsible Gaming</router-link>
                </li>
            </ul>
        </div>
        <div class="more-secure-safe-sec">
            <div class="secure-protected-sec">
                <div class="secure-logo">
                    <img src="@/assets/images/ssl.png" alt="" />
                </div>
                <div class="protected-safe-text">
                    <b>100% SAFE</b>
                    <div>Protected connection and encrypted data.</div>
                </div>
            </div>
            <div class="footer-apk-dflex">
                <div class="f-other">
                    <a href="#eighteen-plus-model" data-bs-toggle="modal">
                        <img src="@/assets/images/18plus.png" alt="" />
                    </a>
                    <a href="https://www.gamcare.org.uk/" target="_blank">
                        <img src="@/assets/images/gamecare.png" alt="" />
                    </a>
                    <a href="https://www.gamblingtherapy.org/" target="_blank">
                        <img src="@/assets/images/gt.png" alt="" />
                    </a>
                </div>
                <div class="download-app">
                    <a href="#install-apk-model" data-bs-toggle="modal">
                        <img src="@/assets/images/android-apk.svg" alt="" />
                    </a>
                </div>
            </div>
        </div>
        <div class="copy-rgt-sec">
            <span>© Copyright 2020. All Rights Reserved.</span>
        </div>
    </footer>

    <div class="eighteenPlusFooter-sec">
        <div class="modal" id="eighteen-plus-model">
            <div class="modal-dialog">
                <div class="modal-content">

                    <div class="eighteen-plus-head">
                        <div class="modal-header">
                            <h4 class="modal-title">hello777.in</h4>
                            <button type="button" class="close" data-bs-dismiss="modal"><i
                                    class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>

                    <div class="BV-modal-content-content">
                        <div class="modal-body">
                            <div class="eighteen-plus-logo">
                                <img src="@/assets/images/18plus.png" alt="">
                                <div class="head-18-plus">
                                    <b>Over 18s only</b>
                                </div>
                            </div>

                            <div class="about-eighteen-plus-text">
                                <p>It is an offence for anyone under the age of 18 to open an account or to gamble on
                                    hello777. hello777 takes its age-verification responsibilities very seriously. We
                                    carry
                                    out age-verification checks on all customers who use payment mechanisms which are
                                    available to under 18s and additionally perform random age-verification checks on
                                    customers using other forms of funding mechanisms. We may ask for information to
                                    verify
                                    your age and could restrict or suspend your account until your age is confirmed.</p>
                            </div>

                            <div class="about-eighteen-plus-text">
                                <p>PLEASE NOTE THAT ANYONE UNDER THE AGE OF 18 YEARS OLD FOUND TO BE USING THIS SITE
                                    WILL
                                    HAVE ANY WINNINGS FORFEITED AND MAY ALSO BE REPORTED TO THE POLICE.</p>
                            </div>

                            <div class="head-18-plus">
                                <b>Filtering systems</b>
                            </div>

                            <div class="about-eighteen-plus-text">
                                <p>hello777 advises and encourages its customers to prevent minors from accessing
                                    gambling
                                    websites.</p>
                                <p>Filtering solutions allow parents to regulate access to the internet, based on chosen
                                    criteria. Parents can use filters to prevent their children from accessing, amongst
                                    other things, gambling websites. Because our pages are 'labelled', filtering
                                    solutions
                                    being used to prevent gambling access are able to detect our site content, and block
                                    our
                                    pages. If you share your computer with friends or family who are under the legal age
                                    to
                                    register or bet with our site, please consider parental filtering solutions such as:
                                </p>
                            </div>

                            <div class="head-18-plus">
                                <span>Net Nanny™ <a href="">www.netnanny.com</a></span>
                            </div>
                            <div class="head-18-plus">
                                <span>CyberPatrol <a href="">www.cyberpatrol.com</a></span>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <DownloadApkModal></DownloadApkModal>

</template>

<script>
import DownloadApkModal from '@/shared/components/modal/DownloadApkModal.vue';
export default {
    name: 'Support',
    components: {
        DownloadApkModal
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },

}
</script>

<style></style>