<template>
    <div class="new-event">
        <div class="new-event-item sport11" v-for="(match, index) in inplay_match_list" :key="index"
            :class="match.sport_id == '4' ? 'sport22' : match.sport_id == '2' ? 'sport33' : 'sport11'">
            <router-link :to="{ name: 'sport-event-detail', params: { event_id: match.event_id } }">
                <i v-if="match.sport_id == '1'" class="fa-solid fa-futbol"></i>
                <i v-if="match.sport_id == '2'" class="fa-solid fa-baseball"></i>
                <i v-if="match.sport_id == '4'" class="fa-solid fa-baseball-bat-ball"></i>
                <span>{{ match.event_name }}</span>
            </router-link>
        </div>
        <!-- <div class="new-event-item sport22">
            <a href="#">
                <i class="fa-solid fa-baseball-bat-ball"></i>
                <span>Sri Lanka v Pakistan</span>
            </a>
        </div>
        <div class="new-event-item sport33">
            <a href="">
                <i class="fa-solid fa-baseball"></i>
                <span>Bonzi v Altmaier</span>
            </a>
        </div>
        <div class="new-event-item sport11">
            <a href="#">
                <i class="fa-solid fa-futbol"></i>
                <span>FIFA Womens World Cup - Winner 2023</span>
            </a>
        </div>
        <div class="new-event-item sport22">
            <a href="#">
                <i class="fa-solid fa-baseball-bat-ball"></i>
                <span>Sri Lanka v Pakistan</span>
            </a>
        </div>
        <div class="new-event-item sport33">
            <a href="">
                <i class="fa-solid fa-baseball"></i>
                <span>Bonzi v Altmaier</span>
            </a>
        </div> -->
    </div>
</template>

<script>
import click_api from "@/shared/services/click_api";
import * as apiName from "@/shared/services/urls";
export default {
    name: 'NewEvent',
    data() {
        return {
            upcoming_matches: null,
            inplayMatches: {},
            inplay_match_list: {},
            inplay_cricket: []
        }
    },
    mounted() {
        // this.getUpcomingMatches()
        this.getInplayMatches()
    },
    methods: {
        getInplayMatches() {
            click_api.get(apiName.INPLAY_MATCHES_LIST).then(response => {
                if (response && response.status == 200) {
                    this.inplay_match_list = response.data.data.inplay
                    this.inplay_match_list.sort((a, b) => {
                        // Define the order of sports based on sport_id
                        const sportsOrder = { 4: 1, 2: 2, 1: 3 };
                        // Get the order of sports for each item
                        const orderA = sportsOrder[a.sport_id] || Number.MAX_SAFE_INTEGER;
                        const orderB = sportsOrder[b.sport_id] || Number.MAX_SAFE_INTEGER;
                        // Compare the orders
                        return orderA - orderB;
                    });

                    this.inplay_cricket = this.inplay_match_list.filter((data) => data.sport_id == 4)
                    console.log("WWWWWWFFFFF", this.inplay_match_list, this.inplay_cricket);
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                }
            });
        },
        timeConverter(datetime) {
            return moment(datetime).format('DD/MM/YYY  h:mm A');
        },
        getUpcomingMatches() {
            click_api.get(apiName.UPCOMING_MATCHES_LIST + '/' + "?day=upcoming").then((response) => {
                console.log(response)
                if (response) {
                    if (response.status === 200) {
                        this.upcoming_matches = response.data.data
                    }
                }
            }).catch(error => {
                console.log("ERROR", error)
            })
        }
    }
}
</script>

<style></style>