import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from '@/modules/authorization/store'
import commonStore from '@/shared/store';

const mutations= {
  resetState(state) {
    state.auth.user=null;
  }
}
const actions = {
  resetAllState({commit}) {
    commit('resetState')
  }
}

export default createStore({
  state: {},
  mutations,
  actions,
  modules: {auth, commonStore},
  plugins: [createPersistedState()]
});