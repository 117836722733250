<template>
    <div class="news-bar-section v-moving-m">
        <div class="moveing-text-body-sec news-and-add-sec">
            <div class="upcoming-fixure">
                <div class="fixure-title">
                    Upcoming Fixure
                </div>
                <marquee behavior="" direction="left">
                    <div class="fixure-box-container">
                        <div class="marquee-inner">
                            <a href="#">
                                <div class="marquee-box">
                                    <span><i class="fa-solid fa-futbol"></i></span>Central Sparks v Western Storm
                                </div>
                                <div class="time-and-date">
                                    12/07/2023 20:30:00 (UTC+05:30)
                                </div>
                            </a>
                        </div>
                        <div class="marquee-inner">
                            <a href="#">
                                <div class="marquee-box">
                                    <span><i class="fa-solid fa-futbol"></i></span>Central Sparks v Western Storm
                                </div>
                                <div class="time-and-date">
                                    12/07/2023 19:30:00 (UTC+05:30)
                                </div>
                            </a>
                        </div>
                        <div class="marquee-inner">
                            <a href="#">
                                <div class="marquee-box">
                                    <span><i class="fa-solid fa-futbol"></i></span>Central Sparks v Western Storm
                                </div>
                                <div class="time-and-date">
                                    12/07/2023 19:30:00 (UTC+05:30)
                                </div>
                            </a>
                        </div>
                        <div class="marquee-inner">
                            <a href="#">
                                <div class="marquee-box">
                                    <span><i class="fa-solid fa-futbol"></i></span>Central Sparks v Western Storm
                                </div>
                                <div class="time-and-date">
                                    12/07/2023 19:30:00 (UTC+05:30)
                                </div>
                            </a>
                        </div>
                        <div class="marquee-inner">
                            <a href="#">
                                <div class="marquee-box">
                                    <span><i class="fa-solid fa-futbol"></i></span>Central Sparks v Western Storm
                                </div>
                                <div class="time-and-date">
                                    12/07/2023 19:30:00 (UTC+05:30)
                                </div>
                            </a>
                        </div>
                        <div class="marquee-inner">
                            <a href="#">
                                <div class="marquee-box">
                                    <span><i class="fa-solid fa-futbol"></i></span>Central Sparks v Western Storm
                                </div>
                                <div class="time-and-date">
                                    12/07/2023 19:30:00 (UTC+05:30)
                                </div>
                            </a>
                        </div>
                        <div class="marquee-inner">
                            <a href="#">
                                <div class="marquee-box">
                                    <span><i class="fa-solid fa-futbol"></i></span>Central Sparks v Western Storm
                                </div>
                                <div class="time-and-date">
                                    12/07/2023 19:30:00 (UTC+05:30)
                                </div>
                            </a>
                        </div>
                        <div class="marquee-inner">
                            <a href="#">
                                <div class="marquee-box">
                                    <span><i class="fa-solid fa-futbol"></i></span>Central Sparks v Western Storm
                                </div>
                                <div class="time-and-date">
                                    12/07/2023 19:30:00 (UTC+05:30)
                                </div>
                            </a>
                        </div>
                    </div>
                </marquee>
            </div>
            <div class="moving-text-title-sec v-moving" v-if="siteSettings?.announcement">
                <marquee behavior="scroll" direction="" scrollamount="10" onmouseover="this.stop();"
                    onmouseout="this.start();">{{
                        siteSettings.announcement.msg }}</marquee>
                   <span class="speaker-img"><img src="@/assets/images/speaker.svg" alt="" /></span>
            </div>
        </div>
        <div class="user-details">
            <div class="points">
                <div class="points-lft">
                    <i class="fa-solid fa-wallet"></i>
                </div>
                <div class="points-rgt">
                    <router-link :to="{name: 'wallet'}">
                        <p>pts: {{userData?.balance}}</p>
                        <p>exp: {{userData?.exposure ? userData.exposure : 0}}</p>
                    </router-link>
                </div>
            </div>
            <div class="user-icons">
                <div class="user-icon-lft">
                    <i class="fa-regular fa-user"></i>
                </div>
                <div class="user-details-rgt">
                    <!-- <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample1" aria-controls="offcanvasExample">
                        {{userData?.userid }}
                    </button> -->
                    <button class="dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"> {{ userData?.userid }}
                    </button>
                    <div class="dropdown-menu dropdown-menu-end animate slideIn mobile-header-dropdownmenu" aria-labelledby="navbarDropdown">
                        <div class="offcanvas-header header-menu-sec">
                            <div class="offcanvas-title" id="offcanvasExampleLabel">
                                <div class="recent-bank-sec1">
                                    <div class="thm-heading">
                                        <h3>{{ userData?.userid }}</h3>
                                        <p>Last Login: {{ userData?.last_login_at }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="switch-theme">
                                <div class="day-theme">
                                    <img src="https://wver.sprintstaticdata.com/v30/static/front/img/icons/sun.svg">
                                </div>
                                <div class="night-theme">
                                    <img src="https://wver.sprintstaticdata.com/v30/static/front/img/icons/moon.svg">
                                </div>
                            </div>
                        </div>
                        <div class="offcanvas-body acc-ofcanvass-body">
                            <ul class="acc-open-menu">
                                <li><router-link :to="{ name: 'account-statment' }"
                                        >Account Statement</router-link></li>
                                <li><router-link :to="{ name: 'current-bets' }"
                                        >Current Bets</router-link></li>
                                <li><button @click="wallet()"
                                        >Wallet</button></li>
                                <li><a class="" href="#language_selection_pop_up" data-bs-toggle="modal">Language</a>
                                </li>
                                <li><router-link :to="{name:'casino-result'} ">Casino Results</router-link></li>
                                <li><a class="" href="#set-value-btn" data-bs-toggle="modal">Set Button
                                        Value</a></li>
                                <li><a class="" href="#change-password" data-bs-toggle="modal"
                                        >Change Password</a></li>
                                <li class="login-seperator"><a @click="logoutBtnClick()">Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AccountSideBar @error-modal="showErrorModalFunc"
		@success-modal="showSuccessModalFunc"></AccountSideBar>
</template>

<script>
import AccountSideBar from './AccountSideBar.vue';
export default {
    name: 'NewsWalletHeader',
    components: {
        AccountSideBar
    },
    data(){
        return{
            userData:null
        }
    },
    computed: {
        siteSettings() {
            console.log("check site setting", this.$store.getters.siteSettings)
            return this.$store.getters.siteSettings;
        },
    },
    mounted(){
        this.userData=this.$store?.getters?.stateUser
    },
    methods:{
        showSuccessModalFunc(message) {
				this.$emit('success-modal', message);
			},
        //  userData(){
        //     return this.$store.getters.stateUser;
        // },
    }
}
</script>

<style></style>