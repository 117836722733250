<template>
    <div class="modal fade" id="locked-bonus-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog modal-dialog-centered information-modal-wrapper">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>Locked Bonus</h3>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="locked-bonus-sec">
                        <div class="locked-bonus-list">
                            <ul>
                                <li>
                                    <div class="locked-bonus-card">
                                        <div class="bonus-lft-bx"><span>₹ 400</span>Bonus</div>
                                        <div class="bonus-rit-bx">
                                            <strong>Deposit Bonus</strong>
                                            <div class="bonus-progress-bx">
                                                <div class="progress" role="progressbar" aria-label="Success example"
                                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-success" style="width: 25%"></div>
                                                </div>
                                            </div>
                                            <div class="rolling-amount">
                                                <span>Rolling Amount:- ₹ 300</span><span>Earn Bonus :- ₹ 100</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="locked-bonus-card">
                                        <div class="bonus-lft-bx"><span>₹ 400</span>Bonus</div>
                                        <div class="bonus-rit-bx">
                                            <strong>Deposit Bonus</strong>
                                            <div class="bonus-progress-bx">
                                                <div class="progress" role="progressbar" aria-label="Success example"
                                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-success" style="width: 33%"></div>
                                                </div>
                                            </div>
                                            <div class="rolling-amount">
                                                <span>Rolling Amount:- ₹ 300</span><span>Earn Bonus :- ₹ 100</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="locked-bonus-card">
                                        <div class="bonus-lft-bx"><span>₹ 400</span>Bonus</div>
                                        <div class="bonus-rit-bx">
                                            <strong>Deposit Bonus</strong>
                                            <div class="bonus-progress-bx">
                                                <div class="progress" role="progressbar" aria-label="Success example"
                                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-success" style="width:99%"></div>
                                                </div>
                                            </div>
                                            <div class="rolling-amount">
                                                <span>Rolling Amount:- ₹ 300</span><span>Earn Bonus :- ₹ 100</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="locked-bonus-card">
                                        <div class="bonus-lft-bx"><span>₹ 400</span>Bonus</div>
                                        <div class="bonus-rit-bx">
                                            <strong>Deposit Bonus</strong>
                                            <div class="bonus-progress-bx">
                                                <div class="progress" role="progressbar" aria-label="Success example"
                                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-success" style="width: 60%"></div>
                                                </div>
                                            </div>
                                            <div class="rolling-amount">
                                                <span>Rolling Amount:- ₹ 300</span><span>Earn Bonus :- ₹ 100</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LockedBonus'
}
</script>