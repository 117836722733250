<template>
    <div class="model-pop-up-login-btn">
        <div class="modal" id="login-btn">
            <div class="modal-dialog">
                <div class="modal-content">

                    <div class="login-header">
                        <div class="modal-header">
                            <h4 class="modal-title">Login Now</h4>
                            <button type="button" class="close" @click="refreshData()" ref="close_btn" data-bs-dismiss="modal"><i
                                    class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>

                    <div class="login-body-sec">
                        <div class="modal-body">
                            <div class="login-body">
                                <div class="tabs-bar">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation" v-if="siteSettings?.business_type == 2">
                                            <button class="nav-link active" id="mobile-tab" data-bs-toggle="tab" data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile" aria-selected="false" tabindex="-1"
                                            @click="form.type = 1">Mobile No.</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="username-tab" :class="siteSettings?.business_type == 1 ? 'active' : ''" data-bs-toggle="tab" data-bs-target="#username" type="button" role="tab" aria-controls="username" aria-selected="true"
                                            @click="form.type = 2">Username</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade active show" id="mobile" role="tabpanel" aria-labelledby="mobile-tab" v-if="siteSettings?.business_type == 2">
                                        <form action="">
                                            <div class="input-field">
                                                <div class="row g-2">
                                                    <div v-if="siteSettings" class="col-3 col-sm-3 col-md-3">
                                                        <select v-model="form.code" class="form-select form-control codein valid"
                                                            aria-label="Default select example" aria-invalid="false">
                                                            <option :value="allowedCountry.phonecode"
                                                                v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                                :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-9 col-sm-9 col-md-9">
                                                        <div class="input-left">
                                                            <input type="tel" v-model="form.mobileNo" maxlength="10" class="form-control" placeholder="phone number*">
                                                            <!-- <i class="fa-solid fa-phone"></i> -->
                                                        </div>
                                                    </div>
                                                    <div v-if="showValidationError && getId()==''" class="mt-2">
                                                        <p class="wrong-message">{{form.type==1?'Please enter mobile number.':'Please enter id.'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="int-container-box input-field">
                                                <input type="password" v-model="form.password" placeholder="password" class="form-control">
                                                <div  v-if="showValidationError && form.password==''" class="mt-2">
                                                    <p class="wrong-message">Please enter password</p>
                                                </div>
                                            </div>


                                            <div class="check-box-sec">
                                                <div class="box-left">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" id="check1" name="option1"
                                                            value="something" checked="">
                                                        <label class="form-check-label" for="check1">Keep Me Logged In</label>
                                                    </div>
                                                </div>
                                                <div class="box-right">
                                                    <a href="#forget-password" data-bs-toggle="modal">Forgot Password</a>
                                                </div>
                                            </div>
                                            <div class="register-btn-sec">
                                                <button type="button" class="btn thm-but"  @click="signInMethodCall()">Log In</button>
                                                <button type="button" class="btn thm-but mt-3" @click="$emit('demo-login')">Login with Demo</button>
                                            </div>

                                            <div class="login-with-social">
                                                <h3 class="whats-with">Or Login With</h3>
                                                <div class="social-login-icon">
                                                    <a :href="siteSettings?.social_link?.facebook_link" target="_blank"><img src="../../../assets/images/facebook-icon.svg"></a>
                                                    <a href="#"><img src="../../../assets/images/google-icon.svg" target="_blank"></a>
                                                    <a :href="siteSettings?.social_link?.instagram_link" target="_blank"><img src="../../../assets/images/instagram-icon.svg"></a>
                                                    <a :href="siteSettings?.social_link?.telegram_link" target="_blank"><img src="../../../assets/images/telegram-icon.svg"></a>
                                                </div>
                                            </div>

                                            <div  v-if="siteSettings?.social_link && siteSettings?.social_link.whatsappno1" class="Continue-with">
                                                <h3 class="whats-with">Or Continue With <span class="whats-with-now-add">
                                                        Whatsapp</span></h3>
                                                <div class="button-whatsapp">
                                                    <a :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank"  class="btn-whatsapp">
                                                        <i class="fa-brands fa-whatsapp"></i> Whatsapp Now
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="acc-and-join-sec">
                                                <span>Don't have an account ?</span>
                                                <span><a href="#join-btn" data-bs-toggle="modal"> Join Now</a></span>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="tab-pane fade show" :class="siteSettings?.business_type == 1 ? 'active' : ''" id="username" role="tabpanel" aria-labelledby="username-tab">
                                        <form action="">
                                            <div class="input-field">
                                                <div class="row g-2">
                                                    <div class="col-12 col-sm-12 col-md-12">
                                                        <div class="input-left">
                                                            <input type="text" v-model="form.userId"  class="form-control" placeholder="Enter ID*">
                                                            <!-- <i class="fa-solid fa-phone"></i> -->
                                                        </div>
                                                    </div>
                                                    <div v-if="showValidationError && getId()==''" class="mt-2">
                                                        <p class="wrong-message">{{form.type==1?'Please enter mobile number.':'Please enter id.'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="int-container-box input-field">
                                                <input type="password" v-model="form.password" placeholder="password" class="form-control">
                                                <div  v-if="showValidationError && form.password==''" class="mt-2">
                                                    <p class="wrong-message">Please enter password</p>
                                                </div>
                                            </div>


                                            <div class="check-box-sec">
                                                <div class="box-left">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" id="check1" name="option1"
                                                            value="something" checked="">
                                                        <label class="form-check-label" for="check1">Keep Me Logged In</label>
                                                    </div>
                                                </div>
                                                <div class="box-right">
                                                    <a href="#forget-password" data-bs-toggle="modal">Forgot Password</a>
                                                </div>
                                            </div>
                                            <div class="register-btn-sec">
                                                <button type="button" class="btn thm-but"  @click="signInMethodCall()">Log In</button>
                                                <button type="button" class="btn thm-but mt-3" @click="$emit('demo-login')">Login with Demo</button>
                                            </div>

                                            <div class="login-with-social">
                                                <h3 class="whats-with">Or Login With</h3>
                                                <div class="social-login-icon">
                                                    <a :href="siteSettings?.social_link?.facebook_link" target="_blank"><img src="../../../assets/images/facebook-icon.svg"></a>
                                                    <a href="#"><img src="../../../assets/images/google-icon.svg" target="_blank"></a>
                                                    <a :href="siteSettings?.social_link?.instagram_link" target="_blank"><img src="../../../assets/images/instagram-icon.svg"></a>
                                                    <a :href="siteSettings?.social_link?.telegram_link" target="_blank"><img src="../../../assets/images/telegram-icon.svg"></a>
                                                </div>
                                            </div>

                                            <div  v-if="siteSettings?.social_link && siteSettings?.social_link.whatsappno1" class="Continue-with">
                                                <h3 class="whats-with">Or Continue With <span class="whats-with-now-add">
                                                        Whatsapp</span></h3>
                                                <div class="button-whatsapp">
                                                    <a :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank"  class="btn-whatsapp">
                                                        <i class="fa-brands fa-whatsapp"></i> Whatsapp Now
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="acc-and-join-sec">
                                                <span>Don't have an account ?</span>
                                                 <span><a href="#join-btn" data-bs-toggle="modal"> Join Now</a></span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import { setHeaders } from '@/shared/services/headers';
import axios from 'axios';
import { Modal } from "bootstrap"

export default {
    name: "Login",
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    inject:['recaptcha'],
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            modal: null,
            captchaData: null
        };
    },
    mounted(){
        if(this.$route.name == 'login' && !this.checkIsLogin()){
            let loginModal = new Modal('#login-btn');
            loginModal.show();
        }
        else if(this.$route.name == 'login' && this.checkIsLogin()){
            this.$router.push('/')
        }
    },
    methods: {
        checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: ''
            },
                this.showValidationError = false
        },
        signInMethodCall() {
            console.log(this.loading)
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "" ) {
                    this.showValidationError = true;
                }
                else {
                    this.loginServiceCall();
                }
            }
        },
        async loginServiceCall() {
            let id = this.getId();
            this.loading = true;
            let recaptchaToken = await this.recaptcha();
            let data = {
                "cncode": this.form.code,
                "userid": id.toString(),
                "type": this.form.type,
                "password": this.form.password,
                "recaptcha_token": recaptchaToken,
              
            };
            api.post(apiName.LOGIN_API, data).then(response => {
                console.log("Result : ", response);
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        console.log("response", response);
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            let userData = response.data.data;
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                             this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                            //for add on all APIs
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            setHeaders()
                            this.setUserDataToStore(userData);
                            this.refreshData();
                            this.$router.go();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                console.log("Error is here : ",error);
                if (error) {
                    console.log("Error Login : ", error[0]);
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
        },
       
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        setUserDataToStore(userData) {
            let user =
            {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                role_id: userData.role_id,
                userid: userData.userid,
                exposure: userData.exposure,
                available_credits: userData.available_credits
            }

            console.log("User : ", user);
            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData.chips);
        }
    },
    components: {  },
};
</script>

<style scoped>
div.mm-dropdown ul li {
    display: flex !important;
    padding-left: 25px;
}
.wrong-message{
    color:red
}
</style>