import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store"
import VueCookies from 'vue3-cookies';
import 'vue3-carousel/dist/carousel.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css";
import "./assets/fontawesome/css/fonts.css";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import './registerServiceWorker'


router.beforeEach((to, from, next) => {
    let routeName=['AboutUs','terms-conditions','responsible-gaming','faq','sport-event-detail','login','sign-up','forget-password'];
    const isLoggedIn = store.getters.isAuthenticated;
    if(routeName.includes(to.name)){
        next();
    } else{
        if (to.path === '/') {
            if (isLoggedIn) {
                next('/home');
            } else {
                next();
            }
        } else if (to.path !== '/') {
            if (isLoggedIn) {
                next();
            } else {
                next('/');
            }
        }
    }
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueCookies);
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_SITEKEY,loaderOptions: {autoHideBadge: true} })
app.mount("#app");

